import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Calendar, Badge, message, Modal } from "antd";
import ContentHeader from "components/header/contentHeader";
import TrainingScheduleModal from "components/modals/trainingScheduleModal";
import moment from "moment";
import { useMutation, Queries, errorHandler } from "apis/config";
import ViewSchedulesModal from "components/modals/viewSchedulesModal";

const ScheduleTraining = (props) => {
  const [addNewSchedule, toggleAddSchedule] = useState(false);
  const [eventModal, setEventModal] = useState({ visible: false, date: "" });
  const [allSchedule, setAllSchedule] = useState({});
  const { mutateAsync, isLoading } = useMutation(Queries.getUserTrainings);

  const getListData = (value) => {
    const listData = allSchedule[`${value.format("YYYY-MM-DD")}`];
    return listData || [];
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    mutateAsync()
      .then(({ data }) => {
        data.data.forEach((item) => {
          if (allSchedule.hasOwnProperty(item.trainingDate)) {
            allSchedule[item.trainingDate].push({
              key: item._id,
              type: item.status == 1 ? "warning" : "success",
              content: item.title,
              data: { ...item },
            });
          } else {
            allSchedule[item.trainingDate] = [
              {
                key: item._id,
                type: item.status == 1 ? "warning" : "success",
                content: item.title,
                data: { ...item },
              },
            ];
          }
          setAllSchedule({ ...allSchedule });
        });
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  console.log(allSchedule[`${eventModal.date}`], eventModal.date);

  return (
    <>
      <ViewSchedulesModal
        visible={eventModal.visible}
        title={`Event (${moment(eventModal.date).format("dddd, DD MMM YYYY")})`}
        onCancel={() => setEventModal({ ...eventModal, visible: false })}
        events={allSchedule[`${eventModal.date}`]}
      />

      <ContentHeader title="Scheduled Training" />

      <StyledDiv>
        <Calendar
          dateCellRender={dateCellRender}
          onSelect={(e) => {
            console.log("Event", moment(e).format("YYYY-MM-DD"));
            if (allSchedule.hasOwnProperty(moment(e).format("YYYY-MM-DD"))) {
              setEventModal({ visible: true, date: moment(e).format("YYYY-MM-DD") });
            }
          }}
        />
      </StyledDiv>
    </>
  );
};

export default ScheduleTraining;

const StyledDiv = styled.div`
  padding: 12px;
  background-color: #fff;
  border-radius: 4px;
`;
