import { useState, useEffect } from "react";
import { useMutation, errorHandler, Queries } from "apis/config";
import { Route, Switch } from "react-router-dom";
import { Layout, message } from "antd";
import MessageList from "components/message";
import HeaderWrapper from "components/header/headerWrapper";
import FooterWrapper from "components/footer/footerWrapper";
import SidebarWrapper from "components/sidebar/sidebarWrapper";
import { useDispatch } from "react-redux";
import { INSTRUCTORS, ALL_USERS } from "redux/constants";
import Dashboard from "./Dashboard";
import Instructors from "./Instructors";
import Users from "./Users";
import AddNewUser from "./AddNewUser";
import ScheduleTraining from "./ScheduleTraining";
import AddInstructor from "./AddInstructor";
import Document from "./Document";
import MessageThread from "./MessageThread";
import TrackUsers from "./TrackUsers";
import InstructorUsers from "./InstructorUsers";
import TrackSingleUser from "./TrackSingleUser";
import DownloadCetner from "./DownloadCenter";
import Feedback from "views/Feedback";
import SubmitFeedback from "./SubmitFeedback";
import Profile from "views/UserShell/Profile";
import Packages from "./Packages";
import Requests from "./Requests";

const { Content } = Layout;

const CompanyShell = () => {
  const dispatch = useDispatch();
  const [collapsed, toggleCollapsed] = useState(true);

  const { mutateAsync, isLoading: getAllInstructorsLoading } = useMutation(
    Queries.getAllInstructors
  );

  const { mutateAsync: getAllUsers, isLoading: getAllUsersLoading } = useMutation(
    Queries.getCompanyUsers
  );

  useEffect(() => {
    mutateAsync()
      .then(({ data }) => {
        dispatch({ type: INSTRUCTORS.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  useEffect(() => {
    const getUsers = () => {
      getAllUsers()
        .then(({ data }) => {
          dispatch({ type: ALL_USERS.STORE, payload: data.data });
        })
        .catch((err) => message.error(errorHandler(err)));
    };
    const myInterval = setInterval(getUsers, 1000 * 60 * 5);
    getUsers();
    return () => clearInterval(myInterval);
  }, []);

  return (
    <div>
      <MessageList />
      <Layout>
        <div style={{ maxHeight: "100vh" }}>
          <SidebarWrapper collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
        </div>

        <Layout>
          <HeaderWrapper collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
          <div
            style={{
              maxHeight: "calc(100vh - 49px)",
              overflow: "auto",
              backgroundColor: "#F4F6FB",
            }}
          >
            <section style={{ minHeight: "calc(100vh - 115px)", overflow: "auto" }}>
              <Content style={{ padding: 44, backgroundColor: "#F4F6FB" }}>
                <Switch>
                  <Route exact path="/" component={Dashboard} />
                  <Route exact path="/schedule-training" component={ScheduleTraining} />
                  <Route exact path="/requests" component={Requests} />
                  <Route exact path="/instructors">
                    <Instructors loading={getAllInstructorsLoading} />
                  </Route>
                  <Route
                    exact
                    path="/instructor/:instructorId/users/"
                    component={InstructorUsers}
                  />
                  <Route exact path="/users">
                    <Users loading={getAllUsersLoading} />
                  </Route>
                  <Route exact path="/add-new-user" component={AddNewUser} />
                  <Route exact path="/add-instructor" component={AddInstructor} />
                  <Route exact path="/documents" component={Document} />
                  <Route exact path="/packages" component={Packages} />
                  <Route exact path="/message/:userId" component={MessageThread} />
                  <Route exact path="/download-center" component={DownloadCetner} />
                  <Route exact path="/track-users" component={TrackUsers} />
                  <Route exact path="/track-user/:userId" component={TrackSingleUser} />
                  <Route exact path="/feedback" component={Feedback} />
                  <Route exact path="/profile" component={Profile} />

                  <Route exact path="/submit-feedback" component={SubmitFeedback} />
                </Switch>
              </Content>
            </section>
            <FooterWrapper />
          </div>
        </Layout>
      </Layout>
    </div>
  );
};

export default CompanyShell;
