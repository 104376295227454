import React, { useState, useEffect } from "react";
import { InputWrapper, PhoneInputWrapper, SelectWrapper } from "../components/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { message, Form, Row, Col, DatePicker } from "antd";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useMutation, errorHandler, Mutations } from "apis/config";
import { ButtonWrapper } from "components/buttons";
import BannerImage from "images/loginTopImage.svg";
import LabbaikLogo from "images/labbaik.png";
import MultiSwitchWrapper from "components/input/multiSwitchWrapper";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import styled from "styled-components";
import moment from "moment";
import { countryList } from "helpers/countries";

const initialState = {
  registerType: "company",
  fullName: "",
  phoneNo: "",
  phoneError: "",
  email: "",
  dob: moment(),
  gender: "",
  city: "",
  country: "",
  password: "",
  trainingType: "",
  companyName: "",
  totalLicense: "",
};

const RegisterAccount = () => {
  const [state, setState] = useState({ ...initialState });
  const [form] = Form.useForm();

  /**
   * Muatation and handler for Register Account
   */
  const { isLoading, mutateAsync } = useMutation(Mutations.registerAccount);
  const [selectedCountry, setSelectedCountry] = useState("");

  const handleRegisterAccount = async () => {
    let variables;
    if (state.registerType === "company") {
      variables = {
        registerType: state.registerType,
        fullName: state.fullName,
        phoneNumber: state.phoneNo,
        email: state.email,
        dob: moment(state.dob).format("YYYY-MM-DD"),
        city: state.city,
        country: state.country,
        password: state.password,
        trainingType: state.trainingType,
        companyName: state.companyName,
        totalLicense: state.totalLicense,
      };
    } else {
      variables = {
        registerType: state.registerType,
        fullName: state.fullName,
        phoneNumber: state.phoneNo,
        email: state.email,
        dob: moment(state.dob).format("YYYY-MM-DD"),
        gender: state.gender,
        city: state.city,
        country: state.country,
        password: state.password,
        trainingType: state.trainingType,
      };
    }
    if (isValidPhoneNumber(state.phoneNo)) {
      try {
        const { data } = await mutateAsync(variables);
        form.resetFields();
        setState({ ...initialState });
        message.success(data.data);
        setTimeout(() => {
          window.open("https://www.labbaikvr.com", "_self");
        }, 2500);
      } catch (err) {
        message.error(errorHandler(err));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    console.log("state--------->", state);
  }, [state]);

  const countryNames = countryList
    .map((item) => {
      return Object.keys(item).map((country) => {
        return { label: country, value: country };
      });
    })
    .flat();

  let cityNames = [];
  if (state.country !== "") {
    cityNames = countryList
      .map((item) => {
        return item[state?.country]?.map((city) => {
          return { label: city, value: city };
        });
      })
      .flat();
  }

  return (
    <StyledContent>
      <div className="card-wrapper">
        <div className="card-banner">
          {/* <div style={{ alignSelf: "flex-start", margin: "25px 0px 0px 15px" }}>
            <div className="banner-wrapper">
              <div style={{ fontWeight: 600, fontSize: 16 }}>Register account as</div>
            </div>
          </div> */}
          <div style={{ minWidth: 200 }}>
            <img alt="app icon" src={LabbaikLogo} className="banner-icon" />
          </div>

          <div style={{ marginTop: 20 }}>
            <p
              style={{
                fontWeight: 600,
                fontSize: 16,
                marginBottom: 10,
                textAlign: "center",
              }}
            >
              Register account as
            </p>
            <MultiSwitchWrapper
              options={[
                { label: "Company", value: "company" },
                { label: "Individual User", value: "user" },
              ]}
              onChange={(val) => {
                console.log("Value", val);
                form.resetFields();
                setState({ ...initialState, registerType: val, phoneError: "" });
              }}
            />
          </div>

          <div>
            <img src={BannerImage} alt="Logo" width={240} />
          </div>

          {/* <div>
            <img src={BannerImage} alt="Logo" width={120} />
          </div> */}
        </div>

        <div style={{ padding: "48px 32px 32px 32px" }}>
          <Form form={form} onFinish={handleRegisterAccount} validateTrigger="onFinish">
            <Row gutter={[24, 0]}>
              <Col span={12}>
                <h4 className="form-label">Full Name</h4>
                <Form.Item name="Full Name" rules={[{ required: true }]}>
                  <InputWrapper
                    name="fullName"
                    value={state.fullName}
                    placeholder="Full Name"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <h4 className="form-label">Phone</h4>
                <PhoneInputWrapper
                  name="phoneNo"
                  autoComplete="new-password"
                  value={state.phoneNo}
                  placeholder="Phone Number"
                  onChange={handleChange}
                  error={state.phoneError}
                />
              </Col>

              <Col span={12}>
                <h4 className="form-label">Email</h4>
                <Form.Item name="Email" rules={[{ type: "email", required: true }]}>
                  <InputWrapper
                    name="email"
                    value={state.email}
                    placeholder="abc@xyz.com"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <h4 className="form-label">Date of birth</h4>
                <Form.Item name="Date of birth" rules={[{ required: true }]}>
                  <DatePicker
                    value={state.dob}
                    style={{
                      height: 40,
                      width: "100%",
                      borderRadius: 4,
                    }}
                    onChange={(e) => setState({ ...state, dob: e })}
                  />
                </Form.Item>
              </Col>

              {state.registerType === "company" ? (
                <Col span={12}>
                  <h4 className="form-label">Company Name</h4>
                  <Form.Item name="Company Name" rules={[{ required: true }]}>
                    <InputWrapper
                      name="companyName"
                      value={state.companyName}
                      placeholder="Company name"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={12}>
                  <h4 className="form-label">Gender</h4>
                  <Form.Item name="Gender" rules={[{ required: true }]}>
                    <SelectWrapper
                      // style={{ height: 40 }}
                      value={state.gender}
                      options={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" },
                      ]}
                      onChange={(val) => {
                        setState({ ...state, gender: val });
                      }}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              )}

              <Col span={12}>
                <h4 className="form-label">Country</h4>
                <Form.Item name="Country" rules={[{ required: true }]}>
                  <SelectWrapper
                    showSearch={true}
                    size="large"
                    value={state.country}
                    options={countryNames}
                    onChange={(val) => setState({ ...state, country: val })}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <h4 className="form-label">City</h4>
                <Form.Item name="City" rules={[{ required: true }]}>
                  <SelectWrapper
                    disabled={state.country == "" ? true : false}
                    placeholder={state.country == "" ? "Select country" : "Select city"}
                    value={state.city}
                    showSearch={true}
                    size="large"
                    options={cityNames}
                    onChange={(val) => setState({ ...state, city: val })}
                  />
                </Form.Item>
              </Col>

              {/* <Col span={12}>
                <h4 className="form-label">City</h4>
                <Form.Item name="City" rules={[{ required: true }]}>
                  <InputWrapper
                    name="city"
                    value={state.city}
                    placeholder="Karachi"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <h4 className="form-label">Country</h4>
                <Form.Item
                  name="Country"
                  rules={[{ required: true }]}
                  initialValue={state.country}
                >
                  <CountryDropdown
                    id="UNIQUE_ID"
                    className="YOUR_CSS_CLASS"
                    name="country"
                    preferredCountries={["pk"]}
                    value={state.country}
                    handleChange={(e) => {
                      let splitted = e.target.value.split(" ");
                      setState({ ...state, country: splitted[0] });
                    }}
                  />
                </Form.Item>
              </Col> */}

              <Col span={12}>
                <h4 className="form-label">Password</h4>
                <Form.Item
                  name="Password"
                  rules={[{ type: "string", min: 3, required: true }]}
                >
                  <InputWrapper.Password
                    name="password"
                    value={state.password}
                    placeholder="Password"
                    onChange={handleChange}
                    autoComplete="new-password"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <h4 className="form-label">Training type</h4>
                <Form.Item name="Training type" rules={[{ required: true }]}>
                  <SelectWrapper
                    style={{ height: 40 }}
                    value={state.trainingType}
                    options={[
                      { label: "Umrah", value: "umrah" },
                      { label: "Hajj", value: "hajj" },
                      { label: "Both", value: "both" },
                    ]}
                    onChange={(val) => {
                      setState({ ...state, trainingType: val });
                    }}
                    size="large"
                  />
                </Form.Item>
              </Col>

              {state.registerType === "company" ? (
                <Col span={12}>
                  <h4 className="form-label">Total License</h4>
                  <Form.Item name="Total License" rules={[{ required: true }]}>
                    <InputWrapper
                      name="totalLicense"
                      value={state.totalLicense}
                      placeholder="50"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              <Col span={24}>
                <ButtonWrapper
                  style={{ marginTop: 16, height: 40 }}
                  disabled={isLoading}
                  htmlType="submit"
                  onClick={() => {
                    if (!isValidPhoneNumber(state.phoneNo)) {
                      setState({
                        ...state,
                        phoneError: "Invalid phone number",
                      });
                    }
                  }}
                >
                  Register
                  {!isLoading ? (
                    <ArrowRightOutlined style={{ marginLeft: 8 }} />
                  ) : (
                    <LoadingOutlined style={{ marginLeft: 8 }} />
                  )}
                </ButtonWrapper>
              </Col>
            </Row>
          </Form>

          <div style={{ marginTop: 24, textAlign: "center" }}>
            Already have an account.{" "}
            <Link to="/" style={{ color: "#000", fontWeight: 600 }}>
              Login
            </Link>
          </div>
        </div>
      </div>
    </StyledContent>
  );
};

export default RegisterAccount;

export const StyledContent = styled.div`
  // background: linear-gradient(90deg, #568eff 50%, #4f56c0 100%);
  background-color: #212121;
  padding: 24px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  * p {
    margin: 0;
  }

  .card-wrapper {
    position: relative;
    width: 900px;
    background-color: #f4f6fb;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }

  .card-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
    background-color: #e0e6f6;
    height: 136px;
    width: -webkit-fill-available;
    width: -moz-available;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .banner-icon {
    font-size: 48px;
    background-color: #fff;
    height: 100px;
    width: 100px;
    line-height: 82px;
    border-radius: 50%;
    // position: absolute;
    // top: 70px;
    // left: 67px;
    margin-top: 90px;
    margin-left: 45px;
  }

  .form-label {
    text-align: left;
    margin-bottom: 8px;
    font-size: 16px;
  }

  .forgot-password a {
    color: #000;
  }
`;
