import { userModule, companyModule } from "./config";
import Cookies from "js-cookie";

export const Queries = {
  getAllInstructors: () => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.get(`/getMyInstructor`, { headers });
  },
  getCompanyDashboardStats: () => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.get(`/getCompanyDashboard`, { headers });
  },
  getCompanyProfile: () => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.get(`/getMyProfile`, { headers });
  },
  getCompanyUsers: () => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.get(`/getMyUser`, { headers });
  },
  getCompanyPackages: () => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.get(`/getMyPackages`, { headers });
  },

  getAllAssosiations: () => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.get(`/getAllAssosiations`, { headers });
  },
  getAllTutorial: () => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.get(`/getAllTutorial`, { headers });
  },
  getUserProfile: ({ userId }) => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.get(`/viewUserProfile/${userId}`, { headers });
  },
  getAllSchedule: () => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.get(`/getAllTrainingsOfCompany`, { headers });
  },
  getMessagesForUser: ({ to }) => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.get(`/${to}`, { headers });
  },
  /**
   * Get message list for company
   */
  getMessagesForCompany: () => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.get(`/getAllMyMessages`, { headers });
  },
  /**
   * Get all messages of a user
   */
  getMessagesThreadOfUser: ({ userId }) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.get(`/getAllMyMessagesToUser/${userId}`, { headers });
  },
  /**
   * Get all messages of a sale
   */
  getAllMyMessagesToSale: () => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.get(`/getAllMyMessagesToSales`, { headers });
  },

  getAllDocumentsOfCompany: () => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.get(`/getAllDocuments`, { headers });
  },
  getAllDocumentsOfUser: () => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.get(`/getAllDocuments`, { headers });
  },
  getUserTrainings: () => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.get(`/getMyScheduledTraining`, { headers });
  },
  getMeetingLink: () => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.get(`/getMeetingLink`, { headers });
  },
  checkAuth: () => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.get(`/checkAuth`, { headers });
  },
  getCompanyApps: () => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.get(`/getAllApplications`, { headers });
  },
  getUserApps: () => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.get(`/getAllApplications`, { headers });
  },

  getQuoteById: ({ quoteId }) => {
    return userModule.get(`/getQuoteById/${quoteId}`);
  },

  // ================COMPANY MODULE================
  getMyComplaints: () => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.get(`/getMyComplaints`, { headers });
  },
};
