import React, { useState, useEffect } from "react";
import { Menu, message } from "antd";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Icon from "@ant-design/icons";
import LiveIcon from "../../images/Red_circle.gif";
import { RiShieldUserFill } from "react-icons/ri";
import { HiOutlineUserGroup } from "react-icons/hi";
import { MdDashboard } from "react-icons/md";
import { BiDownload } from "react-icons/bi";
import { BsCalendarCheck } from "react-icons/bs";
import { IoDocumentsOutline } from "react-icons/io5";
import { FaMapMarkedAlt } from "react-icons/fa";
import { GoPrimitiveDot } from "react-icons/go";
import { Queries, useMutation, errorHandler } from "apis/config";

const GuideSidebar = ({ collapsed }) => {
  //   const [companyProfile, setCompanyProfile] = useState({});
  //   const { mutateAsync: getCompanyProfile, isLoading } = useMutation(
  //     Queries.getCompanyProfile
  //   );

  const location = useLocation();

  //   useEffect(() => {
  //     getCompanyProfile()
  //       .then(({ data }) => {
  //         setCompanyProfile(data.data);
  //       })
  //       .catch((e) => message.error(errorHandler(e)));
  //   }, []);

  return (
    <Menu
      mode="inline"
      theme="dark"
      inlineCollapsed={collapsed}
      selectedKeys={location.pathname}
      disabled={true}
    >
      <Menu.Item key="/" icon={<Icon component={MdDashboard} />}>
        <Link to="/" style={{ color: "#fff" }}>
          Dashboard
        </Link>
      </Menu.Item>

      {/* {isLoading ? (
        <Menu.Item disabled={true} key="/live" icon={<Icon component={GoPrimitiveDot} />}>
          <a href={companyProfile.meetingLink} style={{ color: "#fff" }}>
            Live Training
          </a>
        </Menu.Item>
      ) : (
        <Menu.Item
          disabled={companyProfile.meetingLink !== "" ? false : true}
          key="/live"
          icon={
            companyProfile.meetingLink !== "" ? (
              <Icon
                component={() => <img src={LiveIcon} style={{ height: 15, width: 15 }} />}
              />
            ) : (
              <Icon component={GoPrimitiveDot} />
            )
          }
        >
          <a
            href={companyProfile.meetingLink}
            target={"_blank"}
            style={{ color: "#fff" }}
          >
            Live Training
          </a>
        </Menu.Item>
      )} */}

      <Menu.Item key="/users" icon={<Icon component={HiOutlineUserGroup} />}>
        <Link to="/users" style={{ color: "#fff" }}>
          Users
        </Link>
      </Menu.Item>
      <Menu.Item key="/schedule-training" icon={<Icon component={BsCalendarCheck} />}>
        <Link to="/schedule-training" style={{ color: "#fff" }}>
          Trainings
        </Link>
      </Menu.Item>
      <Menu.Item key="/documents" icon={<Icon component={IoDocumentsOutline} />}>
        <Link to="/documents" style={{ color: "#fff" }}>
          Documents
        </Link>
      </Menu.Item>
      <Menu.Item key="/track-users" icon={<Icon component={FaMapMarkedAlt} />}>
        <Link to="/track-users" style={{ color: "#fff" }}>
          Track Users
        </Link>
      </Menu.Item>
      <Menu.Item key="/download-center" icon={<Icon component={BiDownload} />}>
        <Link to="/download-center" style={{ color: "#fff" }}>
          Download Center
        </Link>
      </Menu.Item>
    </Menu>
  );
};
export default GuideSidebar;
