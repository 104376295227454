import React, { useState, useEffect } from "react";
import { message, Form, Row, Col, DatePicker, Result, Button } from "antd";

import styled from "styled-components";

const StripePaymentError = () => {
  return (
    <StyledContent>
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Button type="primary">Back Home</Button>}
      />
    </StyledContent>
  );
};

export default StripePaymentError;

export const StyledContent = styled.div`
  // background: linear-gradient(90deg, #568eff 50%, #4f56c0 100%);
  /* background-color: #000; */
  padding: 3% 10%;
  min-height: 100vh;
  /* display: flex; */
  /* flex-direction: column;
  justify-content: center; */
  /* align-items: center; */
  color: #fff;
`;
