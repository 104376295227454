import React, { useState } from "react";
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import { useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const TrackUsers = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
    loadingElement: <div style={{ height: `calc(100vh - 200px)` }} />,
    containerElement: <div style={{ height: `calc(100vh - 200px)` }} />,
    mapElement: <div style={{ height: `calc(100vh - 200px)` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(() => {
  const params = useParams();
  const currentUser = useSelector((state) =>
    getUser(state.users.allUsers, params.userId)
  );
  console.log("My current user", currentUser);
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div>
      <GoogleMap
        defaultCenter={{
          lat: Number(currentUser?.location[0]?.latitude),
          lng: Number(currentUser?.location[0]?.longitude),
        }}
        defaultZoom={11}
      >
        <InfoBox
          position={{
            lat: Number(currentUser?.location[0]?.latitude),
            lng: Number(currentUser?.location[0]?.longitude),
          }}
          visible={showInfo}
        >
          <div
            style={{
              backgroundColor: `blue`,
              opacity: 0.6,
              borderRadius: 6,
              padding: `12px`,
              color: "#fff",
              marginBottom: 10,
              minWidth: 150,
            }}
          >
            <div style={{ fontSize: `12px` }}>{currentUser?.email}</div>
          </div>
        </InfoBox>

        <Marker
          position={{
            lat: Number(currentUser?.location[0]?.latitude),
            lng: Number(currentUser?.location[0]?.longitude),
          }}
          onClick={() => {
            setShowInfo(!showInfo);
          }}
        />
      </GoogleMap>
    </div>
  );
});

export default TrackUsers;

const mapUsersLocation = (data = []) => {
  const mappedData = data.reduce((initial, user) => {
    const arrivalDate = moment(user.flightDetails.arrivalDate).format("YYYY-MM-DD");
    const currentDate = moment().format("YYYY-MM-DD");

    if (
      currentDate >= arrivalDate &&
      user.flightDetails.arrivalDate != "" &&
      user.location[0]?.latitude
    ) {
      // console.log("User data", user.fullName, user.location);
      initial.push({
        location: user.location[0] || {},
        email: user.email,
        fullName: user.email,
        pictureURL: user.pictureURL,
      });
    }

    return initial;
  }, []);

  return mappedData;
};

const getUser = (users, userId) => {
  return users.find((user) => user._id === userId);
};
