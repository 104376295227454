import { combineReducers } from "redux";
import userReducer from "./userReducer";
import instructorReducer from "./instructorReducer";
import allUsersReducer from "./allUsersReducer";
import allPackagesReducer from "./allPackagesReducer";
import assosiationReducer from "./assosiationReducer";

export default combineReducers({
  user: userReducer,
  instructors: instructorReducer,
  users: allUsersReducer,
  packages: allPackagesReducer,
  assosiations: assosiationReducer,
});
