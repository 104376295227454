import { useState, useEffect } from "react";
import { Row, Col, Modal, message } from "antd";
import ReactCalendar from "react-calendar";
import moment from "moment";
import { useSelector } from "react-redux";
import { InputWrapper, SelectWrapper } from "components/input";
import { ButtonWrapper } from "components/buttons";
import { FaRegCalendarAlt } from "react-icons/fa";
import { AiOutlineFieldTime } from "react-icons/ai";
import styled from "styled-components";
import { Mutations, useMutation, errorHandler } from "apis/config";

const timeArray = [
  {
    id: 0,
    time: "09:00 AM",
  },
  {
    id: 1,
    time: "09:30 AM",
  },
  {
    id: 2,
    time: "10:00 AM",
  },
  {
    id: 3,
    time: "10:30 AM",
  },
  {
    id: 4,
    time: "11:00 AM",
  },
  {
    id: 5,
    time: "11:30 AM",
  },
  {
    id: 6,
    time: "12:00 PM",
  },
  {
    id: 7,
    time: "12:30 PM",
  },
  {
    id: 8,
    time: "01:00 PM",
  },

  {
    id: 9,
    time: "01:30 PM",
  },
  {
    id: 10,
    time: "02:00 PM",
  },
  {
    id: 11,
    time: "02:30 PM",
  },
  {
    id: 12,
    time: "03:00 PM",
  },
  {
    id: 13,
    time: "03:30 PM",
  },
  {
    id: 14,
    time: "04:00 PM",
  },
  {
    id: 15,
    time: "04:30 PM",
  },
  {
    id: 16,
    time: "05:00 PM",
  },
];

const initialState = { title: "", time: "09:00 AM", date: new Date() };

const TrainingScheduleModal = (props) => {
  const { visible, onCancel } = props;

  const { allInstructors, selectOptions } = useSelector((state) => state.instructors);
  const [newSchedule, setNewSchedule] = useState({ ...initialState });
  const [instructorId, setInstructorId] = useState("");
  const [selectedInstructor, setSelectedInstructor] = useState({ userIds: [] });

  const { mutateAsync: createSchedule, isLoading: createScheduleLoading } = useMutation(
    Mutations.createSchedule
  );

  const handleCreateSchedule = async () => {
    if (newSchedule.title === "") {
      message.error("Title is required");
      return;
    }
    try {
      await createSchedule({
        instructorId: instructorId,
        userIds: selectedInstructor.userIds.map((user) => user._id),
        title: newSchedule.title,
        trainingTime: newSchedule.time,
        trainingDate: moment(newSchedule.date).format("YYYY-MM-DD"),
      });
      window.location.reload();
      setNewSchedule({ ...initialState });
      onCancel();
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const onInstructorsChange = (val) => {
    setInstructorId(val);
    setSelectedInstructor(allInstructors.find((item) => item._id === val));
  };

  useEffect(() => {
    if (selectOptions && selectOptions[0]) {
      setInstructorId(selectOptions[0].value);
      onInstructorsChange(selectOptions[0].value);
    }
  }, [selectOptions]);
  return (
    <Modal
      visible={visible}
      title="Create new schedule"
      onCancel={onCancel}
      width="90%"
      footer={null}
    >
      <ScheduleContainer>
        <Row gutter={[80, 0]}>
          <Col span={7}>
            <div>
              <div className="label">Instructor</div>
              <SelectWrapper
                size="large"
                value={instructorId}
                options={selectOptions}
                onChange={onInstructorsChange}
              />
            </div>
            <br />
            <div>
              <div className="label">Title</div>
              <InputWrapper
                placeholder="Training title"
                value={newSchedule.title}
                onChange={(e) => {
                  setNewSchedule({ ...newSchedule, title: e.target.value });
                }}
              />
            </div>
            <br />
            <div>
              <div className="label">Select Date</div>
              <ReactCalendar
                onChange={(val) => {
                  setNewSchedule({ ...newSchedule, date: val });
                }}
              />
            </div>
          </Col>

          <Col
            span={5}
            style={{
              borderRight: "1px solid rgba(0, 0, 0, 0.09)",
              borderLeft: "1px solid rgba(0, 0, 0, 0.09)",
            }}
          >
            <h3 style={{ textAlign: "center" }}>Select Time</h3>
            <div className="time-div">
              {/* <div> */}
              {timeArray.map((data, index) => (
                <div
                  key={index}
                  className={`time-btn ${
                    newSchedule.time === data.time ? "selcted" : ""
                  }`}
                  onClick={() => setNewSchedule({ ...newSchedule, time: data.time })}
                >
                  {data.time}
                </div>
              ))}
              {/* </div> */}
            </div>
          </Col>
          <Col span={12}>
            <div className="show-schedule-div">
              <div className="header-section">
                <div
                  className="profile-picture"
                  style={{ backgroundImage: `url(${selectedInstructor.pictureURL})` }}
                />
                <h2>{selectedInstructor.fullName}</h2>
                <p style={{ marginBottom: 2 }}>{selectedInstructor.email}</p>
                <p>{selectedInstructor.phoneNumber}</p>
              </div>

              <div className="participants">
                <p style={{ marginBottom: 0, marginRight: 10 }}>Participants:</p>
                {selectedInstructor.userIds.length <= 6 ? (
                  selectedInstructor.userIds.map((user) => (
                    <div
                      key={user._id}
                      className="participants-circle"
                      style={{ backgroundImage: `url(${user.pictureURL})` }}
                    />
                  ))
                ) : (
                  <>
                    {selectedInstructor.userIds.map((user) => (
                      <div
                        key={user._id}
                        className="participants-circle"
                        style={{ backgroundImage: `url(${user.pictureURL})` }}
                      />
                    ))}
                    {` +${selectedInstructor.userIds.length - 6} more`}
                  </>
                )}
              </div>

              <div className="bottom">
                <div style={{ display: "flex", alignItems: "center", marginBottom: 40 }}>
                  <FaRegCalendarAlt className="icons" />
                  <div>
                    <p>{moment(newSchedule.date).format("DD, MMM YYYY")}</p>
                    <p style={{ fontSize: 12 }}>Date</p>
                  </div>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <AiOutlineFieldTime className="icons" />
                  <div>
                    <p>{newSchedule.time}</p>
                    <p style={{ fontSize: 12 }}>Time</p>
                  </div>
                </div>
              </div>

              <ButtonWrapper
                size="large"
                style={{ marginTop: 24 }}
                onClick={handleCreateSchedule}
                loading={createScheduleLoading}
              >
                Schedule
              </ButtonWrapper>
            </div>
          </Col>
        </Row>
      </ScheduleContainer>
    </Modal>
  );
};

export default TrainingScheduleModal;

const ScheduleContainer = styled.div`
  h1 {
    font-size: 32px;
    color: #568eff;
    margin-bottom: 42px;
  }

  .label {
    margin-bottom: 6px;
    font-weight: 600;
    color: #7a6a6a;
    font-size: 14px;
  }

  .time-btn {
    padding: 10px 52px;
    border-radius: 50px;
    color: #000;
    background-color: #fff;
    box-shadow: 1px 1px 10px 1px #e5e5e5;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease;
    width: max-content;
    margin-top: 12px;
    margin-bottom: 12px;
    &:hover {
      opacity: 0.8;
      border: 1px solid #568eff;
      transition: 0.3s ease;
    }

    &:focus {
      border: 1px solid #568eff;
      background-color: #568eff;
      color: #fff;
    }
    &.selcted {
      background-color: #ffbe04;
      font-weight: 600;
    }
  }

  .time-div {
    display: flex;
    align-items: center;
    max-height: 500px;
    overflow: auto;
    justify-content: flex-start;
    max-height: 500px;
    overflow: auto;
    flex-direction: column;
  }

  .show-schedule-div {
    // border-radius: 18px;
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    border-bottom-right-radius: 18px;
    width: 100%;
    background-color: #4f56c0;
    padding: 56px;
    .header-section {
      background-color: #cadbff;
      border-top-right-radius: 18px;
      border-top-left-radius: 18px;
      border-bottom-right-radius: 18px;
      padding: 20px 60px;
      h2 {
        color: #4f56c0;
        font-weight: 700;
      }
      p {
        font-weight: 600;
      }
      .profile-picture {
        position: absolute;
        left: 67px;
        top: 100px;
        height: 60px;
        width: 60px;
        border-radius: 100px;
        border: 2px solid #568eff;
        background-position: center;
        background-size: cover;
        // background-color: rgb(244, 246, 251);
      }
    }

    .participants {
      display: flex;
      align-items: center;
      // justify-content: center;
      margin-top: 32px;
      color: #fff;
      p {
        color: #fff;
      }
      .participants-circle {
        height: 25px;
        width: 25px;
        border-radius: 50px;
        background-position: center;
        background-size: cover;
        margin-left: -5px;
      }
    }

    .bottom {
      margin-top: 30px;
      .icons {
        font-size: 32px;
        color: #fff;
        margin-right: 12px;
      }
      p {
        margin-bottom: 0px;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
      }
    }
  }
`;
