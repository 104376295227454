import styled from "styled-components";
import pdfImage from "images/pdf.png";
import pptImage from "images/ppt.png";
import excelImage from "images/excel.png";
import documentImage from "images/doc.webp";
import { message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useMutation, errorHandler, Mutations } from "apis/config";
import { BiTrash } from "react-icons/bi";

const url = process.env.REACT_APP_BACKEND_URL;

const getImageSrc = (path = "") => {
  const [_, extension] = path.split(".");
  console.log("extension", extension);
  if (extension === "pdf") {
    return pdfImage;
  }
  if (extension === "ppt" || extension === "pptx") {
    return pptImage;
  }
  if (extension === "xls" || extension === "xlsx" || extension === "xlx") {
    return excelImage;
  }
  if (
    extension === "png" ||
    extension === "jpeg" ||
    extension === "jpg" ||
    extension === "webp" ||
    extension === "svg"
  ) {
    return url + path;
  } else {
    return documentImage;
  }
};

const DocumentCard = (props) => {
  const { title, description, filePath, onDelete } = props;

  const { isLoading, mutateAsync } = useMutation(Mutations.deleteDocument);

  const handleDelete = async () => {
    try {
      const { data } = await mutateAsync({ documentId: props._id });
      onDelete(data.data);
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  return (
    <StyledCard>
      <a href={url + filePath} target="_blank">
        <img src={getImageSrc(filePath)} className="preview-image" />
      </a>
      <section style={{ padding: 16 }}>
        <div className="title" title={title}>
          {title}
        </div>
        <div className="description" title={description}>
          {description}
        </div>
      </section>

      <div className="delete-container" onClick={isLoading ? null : handleDelete}>
        {isLoading ? (
          <LoadingOutlined style={{ fontSize: 24, color: "#958383" }} />
        ) : (
          <BiTrash style={{ fontSize: 24, color: "#958383" }} />
        )}
      </div>
    </StyledCard>
  );
};

export default DocumentCard;

const StyledCard = styled.div`
  position: relative;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.03), -2px -2px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 200px;

  .preview-image {
    display: block;
    margin: 12px auto 0 auto;
    height: 150px;
    object-fit: contain;
    cursor: pointer;
    width: 80%;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description {
    color: #958383;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .delete-container {
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    :hover {
      background-color: #95838329;
    }
  }
`;
