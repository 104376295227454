import { useState, useEffect, useRef } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout, message } from "antd";
import HeaderWrapper from "components/header/headerWrapper";
import FooterWrapper from "components/footer/footerWrapper";
import SidebarWrapper from "components/sidebar/sidebarWrapper";
import MessageList from "components/message";
import { errorHandler, useMutation, Mutations } from "apis/config";
import axios from "axios";
/**
 * Routes
 */
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import Download from "./Download";
// import Training from "./Training";
import KnowledgeCenter from "./KnowledgeCenter";
import MessageThread from "views/CompanyShell/MessageThread";
import Document from "./Document";
import ScheduleTraining from "./ScheduleTraining";
import { useSelector } from "react-redux";

const { Content } = Layout;

const UserShell = () => {
  const [collapsed, toggleCollapsed] = useState(true);
  const { companyAssosiated } = useSelector((state) => state.user.data);
  const intervalRef = useRef();
  const { mutateAsync: updateLocation } = useMutation(Mutations.updateMyLoacation);

  const updateMyLocation = async () => {
    try {
      const Geolocation = navigator.geolocation;
      Geolocation.getCurrentPosition(
        ({ coords }) => {
          updateLocation({
            latitude: `${coords.latitude}`,
            longitude: `${coords.longitude}`,
          });
        },
        (e) => {
          console.log("Error", e);
          clearInterval(intervalRef.current);
          return;
        }
      );
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      updateMyLocation();
    }, 1000 * 60 * 2);
    updateMyLocation();
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <div>
      <MessageList />
      <Layout>
        <div style={{ maxHeight: "100vh" }}>
          <SidebarWrapper collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
        </div>

        <Layout>
          <HeaderWrapper collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
          <div
            style={{
              maxHeight: "calc(100vh - 49px)",
              overflow: "auto",
              backgroundColor: "#F4F6FB",
            }}
          >
            <section style={{ minHeight: "calc(100vh - 115px)", overflow: "auto" }}>
              <Content style={{ padding: 44, backgroundColor: "#F4F6FB" }}>
                <Switch>
                  <Route exact path="/" component={Dashboard} />
                  <Route exact path="/download" component={Download} />
                  <Route exact path="/profile" component={Profile} />
                  <Route exact path="/knowledge-center" component={KnowledgeCenter} />
                  <Route exact path="/message/:messageId" component={MessageThread} />
                  {companyAssosiated === "" ? null : (
                    <>
                      <Route exact path="/documents" component={Document} />
                      <Route
                        exact
                        path="/schedule-training"
                        component={ScheduleTraining}
                      />
                    </>
                  )}
                </Switch>
              </Content>
            </section>
            <FooterWrapper />
          </div>
        </Layout>
      </Layout>
    </div>
  );
};

export default UserShell;
