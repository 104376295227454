import React, { useRef, useState } from "react";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { TableButton } from "components/buttons";
import UserDetailModal from "components/modals/userDetailModal";
import { MdOutlineLocationOff, MdOutlineLocationOn } from "react-icons/md";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

const InstructorUser = () => {
  const params = useParams();
  const location = useLocation();
  const [detailModal, toggleDetailModal] = useState(false);
  const user = useRef();
  console.log("location", location);
  return (
    <>
      <UserDetailModal
        title="User details"
        visible={detailModal}
        onCancel={() => toggleDetailModal(false)}
        data={user.current}
      />
      <div>
        <ContentHeader
          title={`instructor/${params.instructorId.replaceAll("-", " ")}/Users`}
        />

        <TableWrapper
          tableData={location.state}
          showPagination={true}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "left", width: "100%" }}>
                    {original.fullName}
                  </div>
                );
              },
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "left", width: "100%" }}>{original.email}</div>
                );
              },
              sortable: false,
            },
            {
              Header: "Phone",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {original.phoneNumber}
                  </div>
                );
              },
              sortable: false,
            },
            {
              Header: "Training Type",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {original.trainingType}
                  </div>
                );
              },
              sortable: false,
            },
            {
              Header: "Track",
              Cell: ({ original }) => {
                let currentTime = moment().format("YYYY-MM-DD");
                let arrivalDate = moment(original?.flightDetails?.arrivalDate).format(
                  "YYYY-MM-DD"
                );
                if (
                  currentTime >= arrivalDate &&
                  original.flightDetails?.arrivalDate != "" &&
                  original.location[0]?.latitude
                ) {
                  return (
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <Link to={`track-user/${original._id}`}>
                        <Tooltip title="Track">
                          <MdOutlineLocationOn
                            style={{
                              fontSize: 22,
                              cursor: "pointer",
                              color: "rgb(19, 91, 237)",
                            }}
                          />
                        </Tooltip>
                      </Link>
                    </div>
                  );
                } else {
                  return (
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <Tooltip title="Cannot Track">
                        <MdOutlineLocationOff
                          style={{
                            fontSize: 22,
                            cursor: "pointer",
                            color: "rgb(19, 91, 237)",
                          }}
                        />
                      </Tooltip>
                    </div>
                  );
                }
              },
              sortable: false,
            },
            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <TableButton
                      onClick={() => {
                        user.current = original;
                        toggleDetailModal(true);
                      }}
                    >
                      view
                    </TableButton>
                  </div>
                );
              },
              sortable: false,
            },
          ]}
        />
      </div>
    </>
  );
};

export default InstructorUser;
