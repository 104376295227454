import React, { useState, useEffect } from "react";
import {
  InputWrapper,
  PhoneInputWrapper,
  SelectWrapper,
  TextAreaWrapper,
} from "../components/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { message, Form, Row, Col, DatePicker, Spin } from "antd";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { useMutation, errorHandler, Mutations, Queries } from "apis/config";
import { ButtonWrapper } from "components/buttons";
import LabbaikLogo from "images/labbaik.png";
import styled from "styled-components";
import moment from "moment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "../stripe.css";
import CheckoutForm from "components/stripe/CheckoutForm";
import Lottie from "react-lottie";
import Successful from "../images/Successful.json";
import Failed from "../images/Failed.json";
import PoweredByStripe from "../images/powered-by-stripe.png";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: Successful,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOptionsFailed = {
  loop: false,
  autoplay: true,
  animationData: Failed,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ContactUs = () => {
  const params = useParams();
  const history = useHistory();
  const [paymentVeriyData, setPaymentVerifyData] = useState("");
  const { mutateAsync, isLoading } = useMutation(Queries.getQuoteById);

  const conditionalRendering = () => {
    if (paymentVeriyData.paymentStatus == "pending") {
      return (
        <CheckoutForm
          paymentVeriyData={paymentVeriyData}
          setPaymentVerifyData={setPaymentVerifyData}
        />
      );
    } else if (paymentVeriyData.paymentStatus == "succeeded") {
      return (
        <div>
          <Lottie options={defaultOptions} height={400} width={400} />
          <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
            Our representative will be in contact you with shortly
          </p>
        </div>
      );
    } else {
      return <Lottie options={defaultOptionsFailed} height={400} width={400} />;
    }
  };

  useEffect(() => {
    mutateAsync({ quoteId: params.quoteId })
      .then(({ data }) => {
        setPaymentVerifyData(data.data);
      })
      .catch((err) => {
        history.push(`/not-found/${params.quoteId}`);
        message.error(errorHandler(err));
      });
  }, []);

  return (
    <StyledContent>
      <Row style={{ paddingBottom: 60 }}>
        <Col sm={24} md={12} lg={12}>
          <h1>PAYMENT</h1>
          <p style={{ fontSize: "1.5rem", marginBottom: 2 }}>
            Payment {paymentVeriyData?.paymentStatus} for{" "}
          </p>
          {paymentVeriyData?.demoId?.userType?.includes("Company") ? (
            <div>
              <p style={{ fontSize: "1.5rem", marginBottom: 2 }}>
                Total User License(s):{" "}
                <span style={{ color: "#dc7e24" }}>
                  {paymentVeriyData?.totalUserLicense}
                </span>
              </p>
              <p style={{ fontSize: "1.5rem", marginBottom: 2 }}>
                Total Instructor License(s):{" "}
                <span style={{ color: "#dc7e24" }}>
                  {paymentVeriyData?.totalInstructorLicense}
                </span>
              </p>
            </div>
          ) : null}
          <p style={{ fontSize: "1.5rem" }}>
            Total Amount:{" "}
            <span style={{ color: "#dc7e24" }}>${paymentVeriyData?.amount}</span>
          </p>
        </Col>
      </Row>
      <Row gutter={[24]} justify="space-between">
        <Col sm={24} md={12} lg={8}>
          <div style={{ minWidth: 200 }}>
            <img alt="app icon" src={LabbaikLogo} className="banner-icon" />
          </div>
          <h3>Labbaik VR Ltd.</h3>
          <p className="address">
            40 Craven Street, Charing Cross, <br /> London, WC2N 5NG, <br /> United
            Kingdom.
          </p>
          <p className="address">
            Email:{" "}
            <span>
              <a href="mailto:info@labbaikvr.com">info@labbaikvr.com</a>
            </span>
          </p>
        </Col>
        <Col
          sm={24}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <Elements stripe={stripePromise}>
            {isLoading ? (
              <div style={{ width: 250 }}>
                <Spin tip="Loading..." size="large">
                  <div className="content" />
                </Spin>
              </div>
            ) : (
              conditionalRendering()
            )}
          </Elements>
        </Col>
      </Row>
      <div style={{ position: "absolute", right: 20, bottom: 20 }}>
        <img src={PoweredByStripe} width={"150px"} />
      </div>
    </StyledContent>
  );
};

export default ContactUs;

export const StyledContent = styled.div`
  // background: linear-gradient(90deg, #568eff 50%, #4f56c0 100%);
  background-color: #000;
  padding: 3% 10%;
  min-height: 100vh;
  /* display: flex; */
  /* flex-direction: column;
  justify-content: center; */
  /* align-items: center; */
  color: #fff;

  .address {
    font-size: 1.3rem;
    /* margin-bottom: ; */
    a {
      color: #dc7e24;
    }
    a:hover {
      color: #b0651d;
    }
  }

  .form-label {
    color: #dc7e24;
    font-size: 1.1rem;
  }

  .banner-icon {
    font-size: 48px;
    background-color: #fff;
    height: 100px;
    width: 100px;
    line-height: 82px;
    border-radius: 50%;
    // position: absolute;
    // top: 70px;
    // left: 67px;
  }

  input {
    height: 50px !important;
  }

  h1 {
    /* font-size: 6rem; */
    font-size: clamp(40px, 15vw, 100px); /* clamp(MIN, VAL, MAX) */

    margin-bottom: 0;
    color: #000;
    -webkit-text-stroke: 1px #dc7e24;
  }
  h3 {
    font-size: 2.8rem;
    margin-bottom: 1px;
    color: #fff;
  }
  p {
    font-size: 2.5rem;
  }
`;
