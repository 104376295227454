import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { TableButton } from "components/buttons";
import UserDetailModal from "components/modals/userDetailModal";
import { MdOutlineLocationOff, MdOutlineLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import moment from "moment";
import { Tooltip, message } from "antd";
import { Queries, useMutation, errorHandler, Mutations } from "apis/config";
import AddNewPackage from "components/modals/addPackageModal";
import { BiTrash } from "react-icons/bi";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { ALL_ASSOSIATION, ALL_PACKAGES } from "redux/constants";
import { HiCheck, HiX } from "react-icons/hi";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 6px;
    margin-right: 6px;

    &:hover {
      background-color: #ececec;
    }
  }
`;

const Requests = (props) => {
  const dispatch = useDispatch();
  //   const [allAssosiation, setAllAssosiation] = useState([]);
  const [userModal, toggleUserModal] = useState(false);

  const { allAssosiation } = useSelector((state) => state.assosiations);
  const userDetails = useRef();
  const assosiationRef = useRef();
  const mode = useRef("View Package");

  const { mutateAsync: getAllAssosiations, isLoading: getAssosiationLoading } =
    useMutation(Queries.getAllAssosiations);

  const { isLoading: updateLoading, mutateAsync: updateAssosiationById } = useMutation(
    Mutations.updateAssosiationById
  );

  useEffect(() => {
    getAllAssosiations()
      .then(({ data }) => {
        dispatch({ type: ALL_ASSOSIATION.STORE, payload: data.data });
      })
      .catch((e) => message.error(errorHandler(e)));
  }, []);

  const handleUpdateAssosiation = async (companyAssosiationId, status) => {
    try {
      let getResponse = await updateAssosiationById({
        companyAssosiationId: companyAssosiationId,
        status: status,
      });
      if (getResponse.data.status == 200 && status == 1) {
        message.success(`${assosiationRef.current.userId.fullName} is now your user`);
        dispatch({ type: ALL_ASSOSIATION.EDIT_DATA, payload: getResponse.data.data });
      } else if (getResponse.data.status == 200 && status == 0) {
        dispatch({ type: ALL_ASSOSIATION.DELETE_DATA, payload: assosiationRef.current });
      }
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  return (
    <StyledTable>
      <UserDetailModal
        title="User details"
        visible={userModal}
        onCancel={() => toggleUserModal(false)}
        data={userDetails.current}
      />

      <div>
        <ContentHeader title="Requests" />
        <TableWrapper
          data={allAssosiation}
          loading={getAssosiationLoading || updateLoading}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {original.userId?.fullName}
                  </div>
                );
              },
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {original.userId?.email}
                  </div>
                );
              },
              sortable: false,
            },
            {
              Header: "Phone",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {original.userId?.phoneNumber}
                  </div>
                );
              },
              sortable: false,
            },
            {
              Header: "Training Type",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {original.userId?.trainingType}
                  </div>
                );
              },
              sortable: false,
            },

            {
              Header: "User Details",
              Cell: ({ original }) => {
                return (
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TableButton
                      onClick={() => {
                        userDetails.current = original.userId;
                        // mode.current = "View Package";
                        toggleUserModal(true);
                      }}
                    >
                      view
                    </TableButton>
                  </div>
                );
              },
              sortable: false,
            },

            {
              Header: "Action",
              Cell: ({ original }) => {
                if (original.status == 0) {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title="Approve (Make user)" placement="bottom">
                        <div
                          className="delete-icon"
                          onClick={() => {
                            assosiationRef.current = original;
                            handleUpdateAssosiation(original._id, 1);
                            // if(original.fullName !== "" && original.email !== "" && original.phoneNumber !== ""){
                            //   inquiriesRef.current = original;
                            //   setInquiryReasonModal({ visible: true, title: "Interested" });
                            // }
                            // else{
                            //   message.warn("Please fill this candidate's information before approving")
                            // }
                          }}
                        >
                          <HiCheck style={{ fontSize: 20, color: "#4fc06b" }} />
                        </div>
                      </Tooltip>
                      <Tooltip title="Reject" placement="bottom">
                        <div
                          className="delete-icon"
                          onClick={() => {
                            assosiationRef.current = original;
                            handleUpdateAssosiation(original._id, 0);
                            // inquiriesRef.current = original;
                            // setInquiryReasonModal({ visible: true, title: "Not Interested" });
                          }}
                        >
                          <HiX style={{ fontSize: 20, color: "#f73838" }} />
                        </div>
                      </Tooltip>
                    </div>
                  );
                } else if (original.status == 1) {
                  return (
                    <div style={{ textAlign: "center", width: "100%", color: "#008000" }}>
                      Approved
                    </div>
                  );
                } else {
                  return (
                    <div style={{ textAlign: "center", width: "100%", color: "red" }}>
                      Rejected
                    </div>
                  );
                }
              },
              sortable: false,
            },
          ]}
        />
      </div>
    </StyledTable>
  );
};

export default Requests;
