import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { TableButton } from "components/buttons";
import UserDetailModal from "components/modals/userDetailModal";
import { MdOutlineLocationOff, MdOutlineLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import moment from "moment";
import { Tooltip, message } from "antd";
import { Queries, useMutation, errorHandler } from "apis/config";

const Users = (props) => {
  const { loading } = props;
  const history = useHistory();
  const [allUsers, setAllUsers] = useState([]);
  // const { allUsers } = useSelector((state) => state.users);
  const [userModal, toggleUserModal] = useState(false);
  const user = useRef();

  const { mutateAsync: getCompanyUsers, isLoading: getUsersLoading } = useMutation(
    Queries.getCompanyUsers
  );

  useEffect(() => {
    getCompanyUsers()
      .then(({ data }) => {
        setAllUsers(data.data);
      })
      .catch((e) => message.error(errorHandler(e)));
  }, []);

  return (
    <>
      <UserDetailModal
        title="User details"
        visible={userModal}
        onCancel={() => toggleUserModal(false)}
        data={user.current}
      />
      <div>
        <ContentHeader title="Users" onAdd={() => history.push("/add-new-user")} />
        <TableWrapper
          data={allUsers}
          loading={getUsersLoading}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "left", width: "100%" }}>
                    {original.fullName}
                  </div>
                );
              },
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "left", width: "100%" }}>{original.email}</div>
                );
              },
              sortable: false,
            },
            {
              Header: "Phone",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {original.phoneNumber}
                  </div>
                );
              },
              sortable: false,
            },
            {
              Header: "Training Type",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {original.trainingType}
                  </div>
                );
              },
              sortable: false,
            },
            {
              Header: "Track",
              Cell: ({ original }) => {
                let currentTime = moment().format("YYYY-MM-DD");
                let arrivalDate = moment(original?.flightDetails?.arrivalDate).format(
                  "YYYY-MM-DD"
                );
                if (
                  currentTime >= arrivalDate &&
                  original.flightDetails?.arrivalDate != "" &&
                  original.location[0]?.latitude
                ) {
                  return (
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <Link to={`track-user/${original._id}`}>
                        <Tooltip title="Track">
                          <MdOutlineLocationOn
                            style={{
                              fontSize: 22,
                              cursor: "pointer",
                              color: "rgb(19, 91, 237)",
                            }}
                          />
                        </Tooltip>
                      </Link>
                    </div>
                  );
                } else {
                  return (
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <Tooltip title="Cannot Track">
                        <MdOutlineLocationOff
                          style={{
                            fontSize: 22,
                            cursor: "pointer",
                            color: "rgb(19, 91, 237)",
                          }}
                        />
                      </Tooltip>
                    </div>
                  );
                }
              },
              sortable: false,
            },
            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <TableButton
                      onClick={() => {
                        user.current = original;
                        toggleUserModal(true);
                      }}
                    >
                      view
                    </TableButton>
                  </div>
                );
              },
              sortable: false,
            },
            // {
            //   Header: "Message",
            //   Cell: ({ original }) => {
            //     return (
            //       <Link to={`/message/${original._id}`}>
            //         <TableButton>Message</TableButton>
            //       </Link>
            //     );
            //   },
            //   sortable: false,
            // },
          ]}
        />
      </div>
    </>
  );
};

export default Users;
