import { useState, useRef, useEffect } from "react";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { TableButton } from "components/buttons";
import { Queries, useMutation, errorHandler } from "apis/config";
import { message, Popover } from "antd";
import moment from "moment";
import styled from "styled-components";

const Feedback = ({ loading }) => {
  const history = useHistory();
  // const { allComplaint } = useSelector((state) => state.instructors);

  const [allComplaint, setallComplaint] = useState([]);

  const { mutateAsync: getMyComplaints, isLoading: getInstructorLoading } = useMutation(
    Queries.getMyComplaints
  );

  useEffect(() => {
    getMyComplaints()
      .then(({ data }) => {
        setallComplaint(data.data);
      })
      .catch((e) => message.error(errorHandler(e)));
  }, []);

  const checkStatus = (status) => {
    if (status == 1) {
      return <span className="pending">Pending</span>;
    } else if (status == 2) {
      return <span className="succeeded">Resolved</span>;
    } else {
      return <span className="succeeded">Appriciated</span>;
    }
  };

  return (
    <StyleWrapper>
      <ContentHeader
        title="Feedback / Complaints"
        onAdd={() => history.push("/submit-feedback")}
      />
      <TableWrapper
        data={allComplaint}
        loading={getInstructorLoading}
        columns={[
          {
            Header: "Subject",
            Cell: ({ original }) => {
              return (
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "40%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Popover
                      style={{ width: "200px" }}
                      content={original.message}
                      title={original.subject}
                      trigger="hover"
                    >
                      {original.subject}
                    </Popover>
                  </div>
                </div>
              );
            },
            sortable: false,
          },
          {
            Header: "Message",
            Cell: ({ original }) => {
              return (
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Popover
                    style={{ width: "200px" }}
                    content={original.message}
                    title={original.subject}
                    trigger="hover"
                  >
                    {original.message}
                  </Popover>
                </div>
              );
            },
            sortable: false,
          },
          {
            Header: "Created",
            Cell: ({ original }) => {
              return (
                <div style={{ textAlign: "center", width: "100%" }}>
                  {moment(original.createdAt).format("DD MMM YYYY")}
                </div>
              );
            },
            sortable: false,
          },

          {
            Header: "Status",
            Cell: ({ original }) => {
              return (
                <div style={{ textAlign: "center", width: "100%" }}>
                  {checkStatus(original.status)}
                </div>
              );
            },
            sortable: false,
          },

          // {
          //   Header: "Users",
          //   Cell: ({ original }) => {
          //     return (
          //       <div style={{ textAlign: "center", width: "100%" }}>
          //         <Link
          //           to={{
          //             pathname: `/instructor/${original.fullName.replaceAll(
          //               " ",
          //               "-"
          //             )}/users`,
          //             state: original.userIds,
          //           }}
          //         >
          //           <TableButton>view</TableButton>
          //         </Link>
          //       </div>
          //     );
          //   },
          //   sortable: false,
          // },
        ]}
      />
    </StyleWrapper>
  );
};

export default Feedback;

const StyleWrapper = styled.div`
  .succeeded {
    background-color: #d4f4e2;
    color: #28c76f;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
  .pending {
    background-color: #ffecd0;
    color: #ff9a00;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
`;
