import React, { useState } from "react";
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import { useSelector } from "react-redux";
import moment from "moment";

const TrackUsers = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
    loadingElement: <div style={{ height: `calc(100vh - 200px)` }} />,
    containerElement: <div style={{ height: `calc(100vh - 200px)` }} />,
    mapElement: <div style={{ height: `calc(100vh - 200px)` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(() => {
  const allUsers = useSelector((state) => mapUsersLocation(state.users.allUsers));
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div>
      <GoogleMap
        defaultCenter={{
          lat: 24.9056,
          lng: 67.0822,
        }}
        defaultZoom={8}
      >
        {allUsers.map((item, key) => {
          // console.log("allUsers", item);
          if (item.location.latitude) {
            return (
              <>
                <InfoBox
                  key={key}
                  // defaultPosition={{
                  //   lat: Number(item.location.latitude),
                  //   lng: Number(item.location.longitude),
                  // }}
                  position={{
                    lat: Number(item.location.latitude),
                    lng: Number(item.location.longitude),
                  }}
                  visible={showInfo === key ? true : false}
                >
                  <div
                    style={{
                      // display:  "block",
                      backgroundColor: `blue`,
                      opacity: 0.6,
                      borderRadius: 6,
                      padding: `12px`,
                      color: "#fff",
                      marginBottom: 10,
                    }}
                  >
                    <div style={{ fontSize: `12px` }}>{item.email}</div>
                  </div>
                </InfoBox>

                <Marker
                  position={{
                    lat: Number(item.location.latitude),
                    lng: Number(item.location.longitude),
                  }}
                  onClick={() => {
                    setShowInfo(key);
                  }}
                />
              </>
            );
          }
        })}
      </GoogleMap>
    </div>
  );
});

export default TrackUsers;

const mapUsersLocation = (data = []) => {
  const mappedData = data.reduce((initial, user) => {
    const arrivalDate = moment(user.flightDetails.arrivalDate).format("YYYY-MM-DD");
    const currentDate = moment().format("YYYY-MM-DD");

    if (
      currentDate >= arrivalDate &&
      user.flightDetails.arrivalDate != "" &&
      user.location[0]?.latitude
    ) {
      // console.log("User data", user.fullName, user.location);
      initial.push({
        location: user.location[0] || {},
        email: user.email,
        fullName: user.email,
        pictureURL: user.pictureURL,
      });
    }

    return initial;
  }, []);

  return mappedData;
};
