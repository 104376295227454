import { useEffect, useState } from "react";
import { Row, Col, Form, message, Modal, Skeleton, DatePicker } from "antd";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { DatePickerWrapper, InputWrapper, SelectWrapper } from "components/input";
import { useDispatch, useSelector } from "react-redux";
import ContentHeader from "components/header/contentHeader";
import moment from "moment";
import { ButtonWrapper } from "components/buttons";
import styledComponents from "styled-components";
import ImageUploader from "components/uploader/ImageUploader";
import { PlusOutlined } from "@ant-design/icons";
import { countryList } from "helpers/countries";
import { updateUserAction } from "redux/actions";

const StyledDiv = styledComponents.div`
  .white-background{
    background-color: #fff;
    padding: 60px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%) !important;
    min-height: 60vh;
    border-radius: 6px;
  }

  .profile-pic {
    height: 120px;
    width: 120px;
    /* background: #222; */
    /* background: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5kyNQIKoyUGJL5ZKSoA5EuHz7rz55ADZ3njzd5VcVTAudAA8Yw9iUyzXsAbOOpUfsk_M&usqp=CAU"); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
  }
  
`;

const labelStyle = {
  marginBottom: 4,
  fontSize: 14,
  color: "#786978ab",
  fontWeight: 600,
};

const Profile = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const { _id, userRole } = useSelector((state) => state.user.data);
  const [imageUrl, setImageUrl] = useState("");
  const [form] = Form.useForm();
  const [passwordModal, togglePasswordModal] = useState(false);
  const [fileList, setFileList] = useState({});
  const [loading, setLoading] = useState(false);

  const { mutateAsync: getUserProfile, isLoading: getUserProfileLoading } = useMutation(
    Queries.getUserProfile
  );
  // const {mutateAsync: updateUserProfile, isLoading: updateUserProfileLoading} = useMutation(Mutations.updateUserProfile)

  const { mutateAsync: changePassword, isLoading: changePasswordLoading } = useMutation(
    Mutations.changePassword
  );

  let countryNames = [];
  let cityNames = [];

  useEffect(() => {
    getUserProfile({ userId: _id })
      .then(({ data }) => {
        setState({ ...data.data });
        setImageUrl(data.data?.pictureURL?.url);
      })

      .catch((err) => message.error(errorHandler(err)));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const { isLoading: updateProfileLoad, mutateAsync } = useMutation(
    Mutations.updateMyProfile
  );
  const handleUpdateProfile = async () => {
    try {
      let getResponse = await mutateAsync({
        ...state,
        pictureURL: fileList.hasOwnProperty("url") ? fileList : state.pictureURL,
      });
      if (getResponse) {
        message.success("Profile updated successfully");
        setState({ ...getResponse.data.data });
        dispatch(updateUserAction({ ...getResponse.data.data }));
        setImageUrl(getResponse.data.data?.pictureURL?.url);
        setFileList([]);
      }
    } catch (err) {
      message.error(errorHandler(err));
      setFileList([]);
    }
  };

  useEffect(() => {
    if (fileList.hasOwnProperty("url")) {
      handleUpdateProfile();
    }
  }, [fileList]);

  const handleChangePassword = async () => {
    if (state.newPassword === state.confirmPassword) {
      try {
        await changePassword({
          currentPassword: state.currentPassword,
          newPassword: state.newPassword,
        });
        togglePasswordModal(false);
        form.resetFields();
        setState({ ...state, currentPassword: "", newPassword: "", confirmPassword: "" });
      } catch (err) {
        message.error(errorHandler(err));
      }
    } else {
      message.error("Password doesn't match");
    }
  };

  countryNames = countryList
    .map((item) => {
      return Object.keys(item).map((country) => {
        return { label: country, value: country };
      });
    })
    .flat();

  if (state.country !== "" || state.country !== undefined) {
    cityNames = countryList
      .map((item) => {
        return item[state?.country]?.map((city) => {
          return { label: city, value: city };
        });
      })
      .flat();
  }

  console.log("cityNames------->", cityNames);

  useEffect(() => {}, [state]);

  return (
    <StyledDiv>
      <Modal
        visible={passwordModal}
        title="Change Passowrd"
        onCancel={() => togglePasswordModal(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleChangePassword}>
          <div style={labelStyle}>Current Passowrd</div>
          <Form.Item name="Current Password" rules={[{ required: true }]}>
            <InputWrapper.Password
              name="currentPassword"
              value={state.currentPassword}
              onChange={handleChange}
              autoComplete="new-password"
            />
          </Form.Item>

          <div style={labelStyle}>New Passowrd</div>
          <Form.Item name="New Password" rules={[{ required: true }]}>
            <InputWrapper.Password
              name="newPassword"
              value={state.newPassword}
              onChange={handleChange}
              autoComplete="new-password"
            />
          </Form.Item>

          <div style={labelStyle}>Confirm Passowrd</div>
          <Form.Item name="Confirm Password" rules={[{ required: true }]}>
            <InputWrapper.Password
              name="confirmPassword"
              value={state.confirmPassword}
              onChange={handleChange}
              autoComplete="new-password"
            />
          </Form.Item>

          <ButtonWrapper
            htmlType="submit"
            loading={changePasswordLoading}
            style={{ height: 40 }}
          >
            Change
          </ButtonWrapper>
        </Form>
      </Modal>

      <div>
        <ContentHeader title="Profile" />
        <div className="white-background">
          <div
            style={{
              width: "100%",
              marginBottom: 50,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {updateProfileLoad || getUserProfileLoading ? (
              <Skeleton.Image
                active={true}
                className="profile-pic"
                style={{ display: "flex", justifyContent: "center" }}
              />
            ) : (
              <div
                title="Upload Profile Picture"
                className="profile-pic"
                style={{
                  backgroundImage: `url(${imageUrl})`,
                }}
              >
                <ImageUploader
                  profileImg={true}
                  uploadLength={1}
                  uploadPreset={"profile_pictures"}
                  loading={loading}
                  setLoading={setLoading}
                  fileList={fileList}
                  setFileList={setFileList}
                  icon={<PlusOutlined style={{ color: "#fff" }} />}
                />
              </div>
            )}
          </div>

          <Form form={form} onFinish={handleUpdateProfile}>
            <Row gutter={[24, 24]}>
              {userKey.map((item) => (
                <>
                  {userRole == 1 && item.key == "companyName" ? null : (
                    <Col key={item.key}>
                      <div style={labelStyle}>{item.label}</div>
                      <Form.Item
                        name={state[item.label]}
                        rules={[
                          {
                            type: "string",
                            required: item.required,
                            message: "Required",
                          },
                        ]}
                        style={{ margin: 0 }}
                        initialValue={moment(moment().format("DD MM YYYY"), "DD MM YYYY")}
                      >
                        {item.key == "dob" ? (
                          <DatePicker
                            disabled={item.key == "email" ? true : false}
                            // bordered={false}
                            name={item.key}
                            value={moment(
                              moment(state[item.key]).format("DD MM YYYY"),
                              "DD MM YYYY"
                            )}
                            defaultValue={moment(
                              moment().format("DD MM YYYY"),
                              "DD MM YYYY"
                            )}
                            onChange={(e, strDate) =>
                              setState({ ...state, dob: strDate })
                            }
                            style={{
                              border: "none",
                              borderRadius: 4,
                              borderBottom: "2px solid #568EFF",
                              backgroundColor: "transparent",
                              outline: "none",
                              boxShadow: "none",
                              padding: 8,
                              width: 200,
                            }}
                          />
                        ) : item.key == "country" ? (
                          <SelectWrapper
                            style={{
                              border: "none",
                              borderRadius: 4,
                              borderBottom: "2px solid #568EFF",
                              backgroundColor: "transparent",
                              outline: "none",
                              boxShadow: "none",
                              width: 200,
                            }}
                            showSearch={true}
                            size="large"
                            bordered={false}
                            value={state.country}
                            options={countryNames}
                            onChange={(val) =>
                              setState({ ...state, country: val, city: "" })
                            }
                          />
                        ) : item.key == "city" ? (
                          <SelectWrapper
                            style={{
                              border: "none",
                              borderRadius: 4,
                              borderBottom: "2px solid #568EFF",
                              backgroundColor: "transparent",
                              outline: "none",
                              boxShadow: "none",
                              width: 200,
                            }}
                            disabled={state.country == "" ? true : false}
                            placeholder={
                              state.country == "" ? "Select country" : "Select city"
                            }
                            showSearch={true}
                            size="large"
                            bordered={false}
                            value={state.city}
                            options={cityNames}
                            onChange={(val) => setState({ ...state, city: val })}
                          />
                        ) : (
                          <InputWrapper
                            disabled={item.key == "email" ? true : false}
                            bordered={false}
                            name={item.key}
                            value={
                              item.format ? item.format(state[item.key]) : state[item.key]
                            }
                            onChange={item.key == "email" ? null : handleChange}
                            style={{
                              border: "none",
                              borderBottom: "2px solid #568EFF",
                              backgroundColor: "transparent",
                              outline: "none",
                              boxShadow: "none",
                              padding: 8,
                            }}
                            // disabled
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                </>
              ))}
            </Row>
            <ButtonWrapper
              loading={updateProfileLoad}
              htmlType="submit"
              style={{ marginTop: 50, width: 400, height: 38 }}
            >
              Update
            </ButtonWrapper>
          </Form>
          {/* <div
            style={{ cursor: "pointer", marginTop: 24, color: "#568eff" }}
            onClick={() => togglePasswordModal(true)}
          >
            Change password
          </div> */}
          <div style={{ width: 200, marginTop: 60 }}>
            <a
              style={{ width: "100%", textDecoration: "underline" }}
              onClick={() => togglePasswordModal(true)}
            >
              Change password
            </a>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

export default Profile;

const userKey = [
  { key: "fullName", label: "Name", required: true },
  { key: "companyName", label: "Company Name", required: true },
  { key: "email", label: "Email", required: false },
  { key: "phoneNumber", label: "Phone", required: true },
  { key: "gender", label: "Gender", required: true },
  {
    key: "dob",
    label: "Date of birth",
    format: (data) => moment(data).format("DD MMM YYYY"),
    required: false,
  },
  { key: "address", label: "Address", required: true },
  { key: "country", label: "Country", required: true },
  { key: "city", label: "City", required: true },
  { key: "medicalHistory", label: "Medical history", required: true },
  { key: "passportNumber", label: "Passport number", required: false },
  { key: "visaNumber", label: "Visa", required: false },
  { key: "bloodType", label: "Blood type", required: false },
  { key: "trainingType", label: "Training type", required: true },
  { key: "age", label: "Age", required: false },
  // Kin information
  { key: "kinName", label: "Kin name", required: false },
  { key: "kinRelation", label: "Kin relation", required: false },
  { key: "kinPhoneNumber", label: "Kin phone number", required: false },
];
