import { userModule, companyModule } from "./config";
import Cookies from "js-cookie";

export const Mutations = {
  registerAccount: (body) => {
    return userModule.post("/register", body);
  },
  login: (body) => {
    return userModule.post("/login", body);
  },
  logout: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.post("/logout", body, { headers });
  },
  forgotPassword: (body) => {
    return userModule.post("/forgetPassword", body);
  },
  resetPassword: (body) => {
    return userModule.post("/changePasswordAfterForget", body);
  },
  createNewUser: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post("/createUser", body, { headers });
  },
  addFlightDetails: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post("/addFlightDetails", body, { headers });
  },
  addHotelDetails: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post("/addHotelDetails", body, { headers });
  },
  createInstructor: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post("/createInstructor", body, { headers });
  },

  changePassword: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.post("/changePassword", body, { headers });
  },
  // ------------UPDATE PROFILE----------------------
  updateMyProfile: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.post("/updateMyProfile", body, { headers });
  },
  createSchedule: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post("/scheduleTraining", body, { headers });
  },
  completeSchedule: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post("/completeTraining", body, { headers });
  },
  updateMeetingLink: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post("/updateMeetingLink", body, { headers });
  },
  addPackageMutation: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post("/createPackage", body, { headers });
  },
  deletePackageMutation: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post("/deletePackage", body, { headers });
  },
  updateAssosiationById: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post("/updateAssosiationById", body, { headers });
  },
  /**
   * Request to send messages to company or admin from user
   */
  sendMessageForUser: ({ message, to }) => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.post(`/${to}`, { message }, { headers });
  },
  /**
   * Request to send messages to users from company
   */
  sendMessageForCompany: ({ message, to }) => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.post(`/${to}`, { message }, { headers });
  },

  /**
   * Request to send messages to sale from company
   */
  sendMessageForSale: ({ message, to }) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post(`/${to}`, { message }, { headers });
  },

  sendMessageToUser: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post(`/replyToUser`, body, { headers });
  },
  addDocument: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post(`/addDocument`, body, { headers });
  },
  updateMyLoacation: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.post(`/updateMyLocation`, body, { headers });
  },
  deleteDocument: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post(`/deleteDocument`, body, { headers });
  },

  sendInquiry: (body) => {
    return userModule.post(`/sendInquiry`, body);
  },

  quotePayment: ({ paymentMethodId, quoteId, email }) => {
    return userModule.post(`/payment/${quoteId}`, { paymentMethodId, email });
  },

  sendComplaint: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return companyModule.post("/sendComplaint", body, { headers });
  },
};
