import { ALL_ASSOSIATION } from "redux/constants";

const initialState = { allAssosiation: [] };

const assosiationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_ASSOSIATION.STORE: {
      const { payload } = action;
      return { ...state, allAssosiation: payload };
    }

    case ALL_ASSOSIATION.CHANGE_STATUS: {
      const { _id, status } = action.payload;
      console.log("action.payload--->", action.payload);
      const currentAdmin = state.allAssosiation.find((admin) => admin._id === _id);
      currentAdmin.status = status;
      return { ...state };
    }

    case ALL_ASSOSIATION.EDIT_DATA: {
      const { payload } = action;
      console.log("action.payload--->", payload);
      const currentAdminIndex = state.allAssosiation.findIndex(
        (assosiation) => assosiation._id === payload._id
      );
      state.allAssosiation.splice(currentAdminIndex, 1, payload);

      return { ...state };
    }

    case ALL_ASSOSIATION.DELETE_DATA: {
      const { payload } = action;
      console.log("action.payload--->", payload);
      const currentIndex = state.allAssosiation.findIndex(
        (assosiation) => assosiation._id === payload._id
      );
      state.allAssosiation.splice(currentIndex, 1);
      return { ...state };
    }

    default:
      return state;
  }
};

export default assosiationReducer;
