import React, { useState, useEffect } from "react";
import {
  InputWrapper,
  PhoneInputWrapper,
  SelectWrapper,
  TextAreaWrapper,
} from "../components/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { message, Form, Row, Col, DatePicker } from "antd";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { useMutation, errorHandler, Mutations } from "apis/config";
import { ButtonWrapper } from "components/buttons";
import LabbaikLogo from "images/labbaik.png";
import styled from "styled-components";

const initialState = {
  fullName: "",
  phoneNo: "",
  phoneError: "",
  email: "",
  message: "",
};

const ContactUs = () => {
  const [state, setState] = useState({ ...initialState });
  const [form] = Form.useForm();

  /**
   * Muatation and handler for Register Account
   */
  const { isLoading, mutateAsync } = useMutation(Mutations.sendInquiry);
  const [selectedCountry, setSelectedCountry] = useState("");

  const handleRegisterAccount = async () => {
    let variables = {
      fullName: state.fullName,
      phoneNumber: state.phoneNo,
      email: state.email,
      message: state.message,
    };

    if (isValidPhoneNumber(state.phoneNo)) {
      try {
        const { data } = await mutateAsync(variables);
        form.resetFields();
        setState({ ...initialState });
        message.success(data.data);
      } catch (err) {
        message.error(errorHandler(err));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <StyledContent>
      <Row style={{ paddingBottom: 60 }}>
        <Col sm={24} md={12} lg={12}>
          <h1>CONTACT US</h1>
          <p>Let's connect and see what we can do together.</p>
        </Col>
      </Row>
      <Row gutter={[24]} justify="space-between">
        <Col sm={24} md={12} lg={8}>
          <div style={{ minWidth: 200 }}>
            <img alt="app icon" src={LabbaikLogo} className="banner-icon" />
          </div>
          <h3>Labbaik VR Ltd.</h3>
          <p className="address">
            40 Craven Street, Charing Cross, <br /> London, WC2N 5NG, <br /> United
            Kingdom.
          </p>
          <p className="address">
            Email:{" "}
            <span>
              <a href="mailto:info@labbaikvr.com">info@labbaikvr.com</a>
            </span>
          </p>
        </Col>
        <Col sm={24} md={12} lg={12}>
          <Form form={form} onFinish={handleRegisterAccount} validateTrigger="onFinish">
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <h4 className="form-label">Full Name*</h4>
                <Form.Item name="Full Name" rules={[{ required: true }]}>
                  <InputWrapper
                    name="fullName"
                    value={state.fullName}
                    placeholder="Full Name"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 0]}>
              <Col span={12}>
                <h4 className="form-label">Email*</h4>
                <Form.Item name="Email" rules={[{ type: "email", required: true }]}>
                  <InputWrapper
                    name="email"
                    value={state.email}
                    placeholder="abc@xyz.com"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <h4 className="form-label">Phone*</h4>
                <PhoneInputWrapper
                  style={{ height: 50 }}
                  name="phoneNo"
                  autoComplete="new-password"
                  value={state.phoneNo}
                  placeholder="Phone Number"
                  onChange={handleChange}
                  error={state.phoneError}
                />
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <h4 className="form-label">Message*</h4>
                <TextAreaWrapper
                  rows={6}
                  name="message"
                  placeholder="Message"
                  value={state.message}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <ButtonWrapper
                  style={{ marginTop: 16, height: 50, width: "100%" }}
                  disabled={isLoading}
                  htmlType="submit"
                  onClick={() => {
                    if (!isValidPhoneNumber(state.phoneNo)) {
                      setState({
                        ...state,
                        phoneError: "Invalid phone number",
                      });
                    }
                  }}
                >
                  SEND MESSAGE
                  {!isLoading ? (
                    <ArrowRightOutlined style={{ marginLeft: 8 }} />
                  ) : (
                    <LoadingOutlined style={{ marginLeft: 8 }} />
                  )}
                </ButtonWrapper>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </StyledContent>
  );
};

export default ContactUs;

export const StyledContent = styled.div`
  // background: linear-gradient(90deg, #568eff 50%, #4f56c0 100%);
  background-color: #000;
  padding: 3% 10%;
  min-height: 100vh;
  /* display: flex; */
  /* flex-direction: column;
  justify-content: center; */
  /* align-items: center; */
  color: #fff;

  .address {
    font-size: 1.3rem;
    /* margin-bottom: ; */
    a {
      color: #dc7e24;
    }
    a:hover {
      color: #b0651d;
    }
  }

  .form-label {
    color: #dc7e24;
    font-size: 1.1rem;
  }

  .banner-icon {
    font-size: 48px;
    background-color: #fff;
    height: 100px;
    width: 100px;
    line-height: 82px;
    border-radius: 50%;
    // position: absolute;
    // top: 70px;
    // left: 67px;
  }

  input {
    height: 50px !important;
  }

  h1 {
    /* font-size: 6rem; */
    font-size: clamp(40px, 15vw, 100px); /* clamp(MIN, VAL, MAX) */

    margin-bottom: 0;
    color: #000;
    -webkit-text-stroke: 1px #dc7e24;
  }
  h3 {
    font-size: 2.8rem;
    margin-bottom: 1px;
    color: #fff;
  }
  p {
    font-size: 2.5rem;
  }
`;
