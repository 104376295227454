import { useState } from "react";
import { Row, Col, Form, message } from "antd";
import styled from "styled-components";
import { InputWrapper, PhoneInputWrapper, TextAreaWrapper } from "components/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ButtonWrapper } from "components/buttons";
import { useMutation, Mutations, errorHandler } from "apis/config";
import { useHistory } from "react-router-dom";
import { INSTRUCTORS } from "redux/constants";
import { useDispatch } from "react-redux";
import ContentHeader from "../../components/header/contentHeader";
import moment from "moment";

const initialState = {
  subject: "",
  message: "",
};

const SubmitFeedback = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({ ...initialState });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const { mutateAsync: createUser, isLoading } = useMutation(Mutations.sendComplaint);

  const handleCreate = async () => {
    try {
      console.log("state", state);
      const { data } = await createUser({
        subject: state.subject,
        message: state.message,
      });
      // dispatch({ type: INSTRUCTORS.INSERT, payload: data.data });
      history.push("/feedback");
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  return (
    <StyledContent>
      <ContentHeader title="Feedback / Complaint" />
      <Form onFinish={handleCreate}>
        <div className="white-background">
          <Row gutter={[32, 0]}>
            <Col span={24}>
              <div className="label">Subject</div>
              <Form.Item name="Subject" rules={[{ required: true }]}>
                <InputWrapper
                  name="subject"
                  value={state.subject}
                  placeholder="Subject"
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <div className="label">Message</div>
              <Form.Item name="Message" rules={[{ required: true }]}>
                <TextAreaWrapper
                  rows={10}
                  name="message"
                  value={state.message}
                  placeholder="We'd love to hear from you.."
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
              <ButtonWrapper
                htmlType="submit"
                style={{ height: 40, width: 400, margin: "16px 0" }}
                loading={isLoading}
              >
                Submit
              </ButtonWrapper>
            </Col>
          </Row>
        </div>
      </Form>
    </StyledContent>
  );
};

export default SubmitFeedback;

const StyledContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .label {
    color: #786161;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .white-background {
    background-color: #fff;
    padding: 60px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%) !important;
    // min-height: 70vh;
    border-radius: 6px;
  }
`;
