import { useState } from "react";
import { Modal, Row, Col, Divider, message } from "antd";
import { ButtonWrapper } from "components/buttons";
import { StyledCheckbox } from "components/input";
import { useMutation, errorHandler, Mutations } from "apis/config";

const CompleteEventModal = (props) => {
  const { visible, onCancel, data } = props;
  const [selectedUsers, setSelectedUsers] = useState([]);
  // console.log("Event data", data);

  const onClose = () => {
    setSelectedUsers([]);
    onCancel();
  };

  const { mutateAsync, isLoading } = useMutation(Mutations.completeSchedule);

  const handleComplete = async () => {
    try {
      const res = await mutateAsync({
        trainingId: data._id,
        userIds: selectedUsers,
      });
      // console.log("Data", res.data.data);
      window.location.reload();
      onClose();
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={"Mark Attendance"}
      width={720}
      footer={null}
    >
      <div className="schedule-wrapper">
        <Row justify="space-between">
          <Col span={8}>
            <div style={{ fontWeight: 600 }}>User name</div>
          </Col>
          <Col span={12}>
            <div style={{ fontWeight: 600 }}>Email</div>
          </Col>
          <Col span={4}>
            <div style={{ fontWeight: 600 }}>Attendance</div>
          </Col>
        </Row>
        {data.userIds.map((user) => (
          <>
            <Divider style={{ margin: "4px 0" }} />
            <Row key={user._id} justify="space-between">
              <Col span={8}>
                <div style={{ textTransform: "capitalize" }}>{user.fullName}</div>
              </Col>
              <Col span={12}>{user.email}</Col>
              <Col span={4}>
                <StyledCheckbox
                  checked={selectedUsers.includes(user._id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      selectedUsers.push(user._id);
                    } else {
                      const index = selectedUsers.indexOf(user._id);
                      selectedUsers.splice(index, 1);
                    }
                    setSelectedUsers([...selectedUsers]);
                  }}
                />
              </Col>
            </Row>
          </>
        ))}
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <ButtonWrapper
          style={{
            width: 200,
            margin: "16px 0 0 0",
          }}
          loading={isLoading}
          onClick={handleComplete}
        >
          Done
        </ButtonWrapper>
      </div>
    </Modal>
  );
};

export default CompleteEventModal;

CompleteEventModal.defaultProps = {
  data: { userIds: [] },
};
