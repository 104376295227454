import { useEffect, useState } from "react";
import { Row, Col, message } from "antd";
import VideoCard from "components/cards/videoCard";
import VideoCardSkeleton from "components/cards/videoCardSkeleton";
import { useMutation, errorHandler, Queries } from "apis/config";
import ContentHeader from "components/header/contentHeader";
import styledComponents from "styled-components";

const StyledDiv = styledComponents.div`
.white-background {
  background-color: #fff;
  padding: 60px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 15%) !important;
  min-height: 60vh;
  border-radius: 6px;
}
`;

const KnowledgeCenter = (props) => {
  const { mutateAsync, isLoading } = useMutation(Queries.getAllTutorial);
  const [knowledgeData, setKnowledgeData] = useState([]);

  useEffect(() => {
    mutateAsync()
      .then(({ data }) => {
        setKnowledgeData(data.data);
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  return (
    <StyledDiv>
      <ContentHeader title="Knowledge Center" />
      <div className="white-background">
        <Row gutter={[24, 24]}>
          {isLoading
            ? [0, 1, 2, 3, 4, 5].map((item) => (
                <Col key={item} sm={12} md={12} lg={8}>
                  <VideoCardSkeleton />
                </Col>
              ))
            : knowledgeData.map((item) => (
                <Col key={item._id} sm={12} md={12} lg={8}>
                  <VideoCard {...item} />
                </Col>
              ))}
        </Row>
      </div>
    </StyledDiv>
  );
};

export default KnowledgeCenter;
