import React, { useState, useEffect } from "react";
import { Menu, message } from "antd";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Icon from "@ant-design/icons";
import LiveIcon from "../../images/Red_circle.gif";
import { BiDownload } from "react-icons/bi";
import { MdVideoLibrary, MdDashboard } from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";
import { BsCalendarCheck } from "react-icons/bs";
import { IoDocumentsOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { GoPrimitiveDot } from "react-icons/go";
import { Queries, useMutation, errorHandler } from "apis/config";

const SidebarContent = ({ collapsed }) => {
  const location = useLocation();
  const { companyAssosiated } = useSelector((state) => state.user.data);

  const { mutateAsync: getMeetingLink, isLoading: meetingLinkLoading } = useMutation(
    Queries.getMeetingLink
  );

  const [meetingLinkState, setMeetingLinkState] = useState("");

  useEffect(() => {
    if (companyAssosiated !== "") {
      getMeetingLink()
        .then(({ data }) => {
          setMeetingLinkState(data.data.meetingLink);
        })
        .catch((e) => message.error(errorHandler(e)));
    }
  }, []);

  return (
    <Menu
      mode="inline"
      theme="dark"
      inlineCollapsed={collapsed}
      selectedKeys={location.pathname}
    >
      <Menu.Item key="/" icon={<Icon component={MdDashboard} />}>
        <Link to="/" style={{ color: "#fff" }}>
          Dashboard
        </Link>
      </Menu.Item>
      {companyAssosiated === "" ? null : (
        <Menu.Item
          disabled={meetingLinkState !== "" ? false : true}
          key="/live"
          icon={
            meetingLinkState !== "" ? (
              <Icon
                component={() => <img src={LiveIcon} style={{ height: 15, width: 15 }} />}
              />
            ) : (
              <Icon component={GoPrimitiveDot} />
            )
          }
        >
          <a href={meetingLinkState} target={"_blank"} style={{ color: "#fff" }}>
            Live Training
          </a>
        </Menu.Item>
      )}
      <Menu.Item key="/download" icon={<Icon component={BiDownload} />}>
        <Link to="/download" style={{ color: "#fff" }}>
          Download Center
        </Link>
      </Menu.Item>
      <Menu.Item key="/profile" icon={<Icon component={RiUserSettingsLine} />}>
        <Link to="/profile" style={{ color: "#fff" }}>
          Profile
        </Link>
      </Menu.Item>
      <Menu.Item key="/knowledge-center" icon={<Icon component={MdVideoLibrary} />}>
        <Link to="/knowledge-center" style={{ color: "#fff" }}>
          Knowledge Center
        </Link>
      </Menu.Item>
      {companyAssosiated === "" ? null : (
        <>
          <Menu.Item key="/schedule-training" icon={<Icon component={BsCalendarCheck} />}>
            <Link to="/schedule-training" style={{ color: "#fff" }}>
              Trainings
            </Link>
          </Menu.Item>

          <Menu.Item key="/documents" icon={<Icon component={IoDocumentsOutline} />}>
            <Link to="/documents" style={{ color: "#fff" }}>
              Documents
            </Link>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
};
export default SidebarContent;
