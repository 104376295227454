import React, { useEffect, useState } from "react";
import ContentHeader from "components/header/contentHeader";
import ReactPlayer from "react-player";
import { Skeleton } from "antd";
import styledComponents from "styled-components";

const StyledDiv = styledComponents.div`
.white-background{
  background-color: #fff;
  padding: 60px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 15%) !important;
  min-height: 60vh;
  border-radius: 6px;
}
`;

const Dashboard = (props) => {
  const [showSkeleton, setShowSkeleton] = useState("flex");

  useEffect(() => {
    setTimeout(() => {
      setShowSkeleton("none");
    }, 500);
  }, []);
  return (
    <StyledDiv>
      <ContentHeader title="Dashboard" />

      <div className="white-background">
        <Skeleton.Image
          style={{ display: showSkeleton, width: "100%", height: 600, borderRadius: 8 }}
        />
        <ReactPlayer
          style={{ position: "relative" }}
          url="https://www.youtube.com/watch?v=PAJ-kAGIaSw"
          width={"100%"}
          height={600}
        />
      </div>
    </StyledDiv>
  );
};

export default Dashboard;
