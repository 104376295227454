import React, { useState, useEffect } from "react";
import ContentHeader from "components/header/contentHeader";
import styled from "styled-components";
import { Row, Col, message, Skeleton } from "antd";
import { MdOutlineFileDownload } from "react-icons/md";
import { useMutation, Queries, errorHandler } from "apis/config";
import { useSelector } from "react-redux";
import NotFoundImage from "../../images/not-found.gif";

const backendURL = process.env.REACT_APP_BACKEND_ADMIN_URL;

const Download = () => {
  const { companyAssosiated } = useSelector((state) => state.user.data);
  const [appsData, setAppsData] = useState({});
  const { mutateAsync, isLoading } = useMutation(Queries.getUserApps);

  useEffect(() => {
    mutateAsync()
      .then(({ data }) => {
        setAppsData(mapData(data.data));
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  if (
    appsData[`1`] == null &&
    appsData[`2`] == null &&
    appsData[`4`] == null &&
    isLoading === false
  ) {
    return (
      <StyledDiv>
        <ContentHeader title="Download Center" />
        <div className="white-background">
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <img src={NotFoundImage} style={{ height: 300, width: 300 }} />
              <p
                style={{
                  fontSize: 18,
                  color: "rgb(86, 142, 255)",
                  textAlign: "center",
                }}
              >
                No application uploaded
              </p>
            </div>
          </Col>
        </div>
      </StyledDiv>
    );
  }

  return (
    <StyledDiv>
      <ContentHeader title="Download Center" />
      <div className="white-background">
        {isLoading ? (
          <Skeleton />
        ) : (
          <Row gutter={[0, 60]}>
            {appsData[`1`] ? (
              <Col span={24}>
                <div
                  className="buttons"
                  onClick={() =>
                    window.open(`${backendURL}${appsData[`1`].filePath}`, "_target")
                  }
                >
                  <MdOutlineFileDownload className="download-icon" />
                  Self learning application
                </div>
              </Col>
            ) : null}

            {appsData[`2`] && companyAssosiated !== "" ? (
              <Col span={24} className="buttons">
                <div
                  className="buttons"
                  onClick={() =>
                    window.open(`${backendURL}${appsData[`2`].filePath}`, "_target")
                  }
                >
                  <MdOutlineFileDownload className="download-icon" />
                  Multi player application
                </div>
              </Col>
            ) : null}

            {appsData[`4`] ? (
              <Col span={24}>
                <div
                  className="buttons"
                  onClick={() =>
                    window.open(`${backendURL}${appsData[`4`].filePath}`, "_target")
                  }
                >
                  <MdOutlineFileDownload className="download-icon" />
                  Labbaik application
                </div>
              </Col>
            ) : null}
          </Row>
        )}
      </div>
    </StyledDiv>
  );
};

export default Download;

const StyledDiv = styled.div`
  .white-background {
    background-color: #fff;
    padding: 60px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%) !important;
    border-radius: 6px;
  }

  .buttons {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #568eff;
    border-radius: 4px;
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease;
    &:hover {
      transition: 0.3s ease;
      opacity: 0.8;
    }
  }

  .download-icon {
    font-size: 40px;
    margin-right: 32px;
  }
`;

const mapData = (data) => {
  const mappedData = {};
  data.forEach((item) => {
    mappedData[item.fileType] = item;
  });
  return mappedData;
};
