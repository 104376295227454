import { useState, useRef, useEffect } from "react";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { TableButton } from "components/buttons";
import { Queries, useMutation, errorHandler } from "apis/config";
import { message } from "antd";

const Instructors = ({ loading }) => {
  const history = useHistory();
  // const { allInstructors } = useSelector((state) => state.instructors);

  const [allInstructors, setAllInstructors] = useState([]);

  const { mutateAsync: getAllInstructors, isLoading: getInstructorLoading } = useMutation(
    Queries.getAllInstructors
  );

  useEffect(() => {
    getAllInstructors()
      .then(({ data }) => {
        setAllInstructors(data.data);
      })
      .catch((e) => message.error(errorHandler(e)));
  }, []);

  return (
    <div>
      <ContentHeader title="Instructors" onAdd={() => history.push("/add-instructor")} />
      <TableWrapper
        data={allInstructors}
        loading={getInstructorLoading}
        columns={[
          {
            Header: "Name",
            Cell: ({ original }) => {
              return (
                <div style={{ textAlign: "left", width: "100%" }}>
                  {original.fullName}
                </div>
              );
            },
            sortable: false,
          },
          {
            Header: "Email",
            Cell: ({ original }) => {
              return (
                <div style={{ textAlign: "left", width: "100%" }}>{original.email}</div>
              );
            },
            sortable: false,
          },
          {
            Header: "Phone",
            Cell: ({ original }) => {
              return (
                <div style={{ textAlign: "center", width: "100%" }}>
                  {original.phoneNumber}
                </div>
              );
            },
            sortable: false,
          },
          {
            Header: "No. of users",
            Cell: ({ original }) => {
              return (
                <div style={{ textAlign: "center", width: "100%" }}>
                  {original.userIds.length}
                </div>
              );
            },
            sortable: false,
          },
          {
            Header: "Trainings Completed",
            accessor: "trainingCompleted",
            Cell: ({ original }) => {
              return (
                <div style={{ textAlign: "center", width: "100%" }}>
                  {original.trainingCompleted}
                </div>
              );
            },
            sortable: false,
          },
          {
            Header: "Users",
            Cell: ({ original }) => {
              return (
                <div style={{ textAlign: "center", width: "100%" }}>
                  <Link
                    to={{
                      pathname: `/instructor/${original.fullName.replaceAll(
                        " ",
                        "-"
                      )}/users`,
                      state: original.userIds,
                    }}
                  >
                    <TableButton>view</TableButton>
                  </Link>
                </div>
              );
            },
            sortable: false,
          },
        ]}
      />
    </div>
  );
};

export default Instructors;
