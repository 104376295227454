import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Modal, Form, message, Button } from "antd";
import { InputWrapper } from "../input";
import { Mutations } from "../../apis/mutations";
import { useMutation } from "react-query";
import { errorHandler } from "../../helpers/errorHandler";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import SelectWrapper from "../input/selectWrapper";
import { BsPlusSquare } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import { HiCheck, HiX } from "react-icons/hi";
import { ALL_PACKAGES } from "redux/constants";
import ImageUploader from "components/uploader/ImageUploader";
import { NumericFormat } from "react-number-format";
import BannerImageUploader from "components/uploader/BannerImageUploader";

const StyledContent = styled.div`
  .numeric-formatt {
    ::placeholder {
      color: #d2d2d2;
    }
  }
  .form-label {
    text-align: left;
    font-size: 16px;
  }

  .ant-form-item {
    margin: 0px;
  }

  .add-btn {
    background-color: #135bed;
    color: #fff;
    padding: 6px 16px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    font-size: 12px;
  }

  .description-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    background-color: #f0f3ff;
    padding: 0px 10px;
    margin: 6px 6px;
    border-radius: 4px;
    border: 1px solid #f0f3ff;
    min-width: 80px;
    border-radius: 50px;
    max-width: max-content;
  }

  .description {
    font-size: 12px;
    color: #2a2a2a;
  }
  .delete-description {
    font-size: 14px;
    color: #2a2a2a;
    cursor: pointer;
    margin-left: 4px;
  }
  .description-container {
    max-height: 180px;
    overflow: auto;
    flex-wrap: wrap;
    display: flex;
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #d1d1d1ef;
      border-radius: 10px;
    }
  }
`;

const initialState = {
  packageName: "",
  packageType: "",
  packageDuration: "",
  packageDetail: "",
  price: "",
  image: "",
  packageIncludes: [],
};

const cvvFomatter = (object) => {
  console.log("object", object.target.value);
  var numbers = /^[0-9]+$/;
  if (object.target.value.match(numbers)) {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
  } else {
    object.target.value = "";
  }
};

const currencies = [
  {
    id: 1,
    country: "Afghanistan",
    symbol: "؋",
    currency: "AFN",
  },
  {
    id: 2,
    country: "Albania",
    symbol: "L",
    currency: "ALL",
  },
  {
    id: 3,
    country: "Algeria",
    symbol: "د.ج",
    currency: "DZD",
  },
  {
    id: 4,
    country: "Andorra",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 5,
    country: "Angola",
    symbol: "Kz",
    currency: "AOA",
  },
  {
    id: 6,
    country: "Antigua and Barbuda",
    symbol: "$",
    currency: "XCD",
  },
  {
    id: 7,
    country: "Argentina",
    symbol: "$",
    currency: "ARS",
  },
  {
    id: 8,
    country: "Armenia",
    symbol: "֏",
    currency: "AMD",
  },
  {
    id: 9,
    country: "Australia",
    symbol: "$",
    currency: "AUD",
  },
  {
    id: 10,
    country: "Austria",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 11,
    country: "Azerbaijan",
    symbol: "₼",
    currency: "AZN",
  },
  {
    id: 12,
    country: "Bahamas",
    symbol: "$",
    currency: "BSD",
  },
  {
    id: 13,
    country: "Bahrain",
    symbol: ".د.ب",
    currency: "BHD",
  },
  {
    id: 14,
    country: "Bangladesh",
    symbol: "৳",
    currency: "BDT",
  },
  {
    id: 15,
    country: "Barbados",
    symbol: "$",
    currency: "BBD",
  },
  {
    id: 16,
    country: "Belarus",
    symbol: "Br",
    currency: "BYN",
  },
  {
    id: 17,
    country: "Belgium",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 18,
    country: "Belize",
    symbol: "$",
    currency: "BZD",
  },
  {
    id: 19,
    country: "Benin",
    symbol: "CFA",
    currency: "XOF",
  },
  {
    id: 20,
    country: "Bhutan",
    symbol: "Nu.",
    currency: "BTN",
  },
  {
    id: 21,
    country: "Bolivia",
    symbol: "Bs.",
    currency: "BOB",
  },
  {
    id: 22,
    country: "Bosnia and Herzegovina",
    symbol: "KM",
    currency: "BAM",
  },
  {
    id: 23,
    country: "Botswana",
    symbol: "P",
    currency: "BWP",
  },
  {
    id: 24,
    country: "Brazil",
    symbol: "R$",
    currency: "BRL",
  },
  {
    id: 25,
    country: "Brunei",
    symbol: "$",
    currency: "BND",
  },
  {
    id: 26,
    country: "Bulgaria",
    symbol: "лв",
    currency: "BGN",
  },
  {
    id: 27,
    country: "Burkina Faso",
    symbol: "CFA",
    currency: "XOF",
  },
  {
    id: 28,
    country: "Burundi",
    symbol: "Fr",
    currency: "BIF",
  },
  {
    id: 29,
    country: "Cabo Verde",
    symbol: "$",
    currency: "CVE",
  },
  {
    id: 30,
    country: "Cambodia",
    symbol: "៛",
    currency: "KHR",
  },
  {
    id: 31,
    country: "Cameroon",
    symbol: "CFA",
    currency: "XAF",
  },
  {
    id: 32,
    country: "Canada",
    symbol: "$",
    currency: "CAD",
  },
  {
    id: 33,
    country: "Central African Republic",
    symbol: "CFA",
    currency: "XAF",
  },
  {
    id: 34,
    country: "Chad",
    symbol: "CFA",
    currency: "XAF",
  },
  {
    id: 35,
    country: "Chile",
    symbol: "$",
    currency: "CLP",
  },
  {
    id: 36,
    country: "China",
    symbol: "¥",
    currency: "CNY",
  },
  {
    id: 37,
    country: "Colombia",
    symbol: "$",
    currency: "COP",
  },
  {
    id: 38,
    country: "Comoros",
    symbol: "Fr",
    currency: "KMF",
  },
  {
    id: 39,
    country: "Congo (Congo-Brazzaville)",
    symbol: "Fr",
    currency: "XAF",
  },
  {
    id: 40,
    country: "Costa Rica",
    symbol: "₡",
    currency: "CRC",
  },
  {
    id: 41,
    country: "Côte d'Ivoire",
    symbol: "CFA",
    currency: "XOF",
  },
  {
    id: 42,
    country: "Croatia",
    symbol: "kn",
    currency: "HRK",
  },
  {
    id: 43,
    country: "Cuba",
    symbol: "₱",
    currency: "CUP",
  },
  {
    id: 44,
    country: "Cyprus",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 45,
    country: "Czechia (Czech Republic)",
    symbol: "Kč",
    currency: "CZK",
  },
  {
    id: 46,
    country: "Democratic Republic of the Congo",
    symbol: "Fr",
    currency: "CDF",
  },
  {
    id: 47,
    country: "Denmark",
    symbol: "kr",
    currency: "DKK",
  },
  {
    id: 48,
    country: "Djibouti",
    symbol: "Fr",
    currency: "DJF",
  },
  {
    id: 49,
    country: "Dominica",
    symbol: "$",
    currency: "XCD",
  },
  {
    id: 50,
    country: "Dominican Republic",
    symbol: "RD$",
    currency: "DOP",
  },
  {
    id: 51,
    country: "Ecuador",
    symbol: "$",
    currency: "USD",
  },
  {
    id: 52,
    country: "Egypt",
    symbol: "£",
    currency: "EGP",
  },
  {
    id: 53,
    country: "El Salvador",
    symbol: "$",
    currency: "USD",
  },
  {
    id: 54,
    country: "Equatorial Guinea",
    symbol: "CFA",
    currency: "XAF",
  },
  {
    id: 55,
    country: "Eritrea",
    symbol: "Nkf",
    currency: "ERN",
  },
  {
    id: 56,
    country: "Estonia",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 57,
    country: `Eswatini (fmr. "Swaziland")`,
    symbol: "L",
    currency: "SZL",
  },
  {
    id: 58,
    country: "Ethiopia",
    symbol: "Br",
    currency: "ETB",
  },
  {
    id: 59,
    country: "Fiji",
    symbol: "$",
    currency: "FJD",
  },
  {
    id: 60,
    country: "Finland",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 61,
    country: "France",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 62,
    country: "Gabon",
    symbol: "CFA",
    currency: "XAF",
  },
  {
    id: 63,
    country: "Gambia",
    symbol: "D",
    currency: "GMD",
  },
  {
    id: 64,
    country: "Georgia",
    symbol: "₾",
    currency: "GEL",
  },
  {
    id: 65,
    country: "Germany",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 66,
    country: "Ghana",
    symbol: "₵",
    currency: "GHS",
  },
  {
    id: 67,
    country: "Greece",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 68,
    country: "Grenada",
    symbol: "$",
    currency: "XCD",
  },
  {
    id: 69,
    country: "Guatemala",
    symbol: "Q",
    currency: "GTQ",
  },
  {
    id: 70,
    country: "Guinea",
    symbol: "Fr",
    currency: "GNF",
  },
  {
    id: 71,
    country: "Guinea-Bissau",
    symbol: "CFA",
    currency: "XOF",
  },
  {
    id: 72,
    country: "Guyana",
    symbol: "$",
    currency: "GYD",
  },
  {
    id: 73,
    country: "Haiti",
    symbol: "G",
    currency: "HTG",
  },
  {
    id: 74,
    country: "Holy See",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 75,
    country: "Honduras",
    symbol: "L",
    currency: "HNL",
  },
  {
    id: 76,
    country: "Hungary",
    symbol: "Ft",
    currency: "HUF",
  },
  {
    id: 77,
    country: "Iceland",
    symbol: "kr",
    currency: "ISK",
  },
  {
    id: 78,
    country: "India",
    symbol: "₹",
    currency: "INR",
  },
  {
    id: 79,
    country: "Indonesia",
    symbol: "Rp",
    currency: "IDR",
  },
  {
    id: 80,
    country: "Iran",
    symbol: "﷼",
    currency: "IRR",
  },
  {
    id: 81,
    country: "Iraq",
    symbol: "ع.د",
    currency: "IQD",
  },
  {
    id: 82,
    country: "Ireland",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 83,
    country: "Israel",
    symbol: "₪",
    currency: "ILS",
  },
  {
    id: 84,
    country: "Italy",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 85,
    country: "Jamaica",
    symbol: "J$",
    currency: "JMD",
  },
  {
    id: 86,
    country: "Japan",
    symbol: "¥",
    currency: "JPY",
  },
  {
    id: 87,
    country: "Jordan",
    symbol: "د.ا",
    currency: "JOD",
  },
  {
    id: 88,
    country: "Kazakhstan",
    symbol: "₸",
    currency: "KZT",
  },
  {
    id: 89,
    country: "Kenya",
    symbol: "Ksh",
    currency: "KES",
  },
  {
    id: 90,
    country: "Kiribati",
    symbol: "$",
    currency: "AUD",
  },
  {
    id: 91,
    country: "Kuwait",
    symbol: "د.ك",
    currency: "KWD",
  },
  {
    id: 92,
    country: "Kyrgyzstan",
    symbol: "лв",
    currency: "KGS",
  },
  {
    id: 93,
    country: "Laos",
    symbol: "₭",
    currency: "LAK",
  },
  {
    id: 94,
    country: "Latvia",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 95,
    country: "Lebanon",
    symbol: "ل.ل",
    currency: "LBP",
  },
  {
    id: 96,
    country: "Lesotho",
    symbol: "L",
    currency: "LSL",
  },
  {
    id: 97,
    country: "Liberia",
    symbol: "$",
    currency: "LRD",
  },
  {
    id: 98,
    country: "Libya",
    symbol: "ل.د",
    currency: "LYD",
  },
  {
    id: 99,
    country: "Liechtenstein",
    symbol: "CHF",
    currency: "CHF",
  },
  {
    id: 100,
    country: "Lithuania",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 101,
    country: "Luxembourg",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 102,
    country: "Madagascar",
    symbol: "Ar",
    currency: "MGA",
  },
  {
    id: 103,
    country: "Malawi",
    symbol: "MK",
    currency: "MWK",
  },
  {
    id: 104,
    country: "Malaysia",
    symbol: "RM",
    currency: "MYR",
  },
  {
    id: 105,
    country: "Maldives",
    symbol: "Rf",
    currency: "MVR",
  },
  {
    id: 106,
    country: "Mali",
    symbol: "CFA",
    currency: "XOF",
  },
  {
    id: 107,
    country: "Malta",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 108,
    country: "Marshall Islands",
    symbol: "$",
    currency: "USD",
  },
  {
    id: 109,
    country: "Mauritania",
    symbol: "UM",
    currency: "MRU",
  },
  {
    id: 110,
    country: "Mauritius",
    symbol: "₨",
    currency: "MUR",
  },
  {
    id: 111,
    country: "Mexico",
    symbol: "$",
    currency: "MXN",
  },
  {
    id: 112,
    country: "Micronesia",
    symbol: "$",
    currency: "USD",
  },
  {
    id: 113,
    country: "Moldova",
    symbol: "L",
    currency: "MDL",
  },
  {
    id: 114,
    country: "Monaco",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 115,
    country: "Mongolia",
    symbol: "₮",
    currency: "MNT",
  },
  {
    id: 116,
    country: "Montenegro",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 117,
    country: "Morocco",
    symbol: "د.م.",
    currency: "MAD",
  },
  {
    id: 118,
    country: "Mozambique",
    symbol: "MT",
    currency: "MZN",
  },
  {
    id: 119,
    country: "Myanmar (formerly Burma)",
    symbol: "K",
    currency: "MMK",
  },
  {
    id: 120,
    country: "Namibia",
    symbol: "N$",
    currency: "NAD",
  },
  {
    id: 121,
    country: "Nauru",
    symbol: "$",
    currency: "AUD",
  },
  {
    id: 122,
    country: "Nepal",
    symbol: "₨",
    currency: "NPR",
  },
  {
    id: 123,
    country: "Netherlands",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 124,
    country: "New Zealand",
    symbol: "$",
    currency: "NZD",
  },
  {
    id: 125,
    country: "Nicaragua",
    symbol: "C$",
    currency: "NIO",
  },
  {
    id: 126,
    country: "Niger",
    symbol: "CFA",
    currency: "XOF",
  },
  {
    id: 127,
    country: "Nigeria",
    symbol: "₦",
    currency: "NGN",
  },
  {
    id: 128,
    country: "North Korea",
    symbol: "₩",
    currency: "KPW",
  },
  {
    id: 129,
    country: "North Macedonia",
    symbol: "ден",
    currency: "MKD",
  },
  {
    id: 130,
    country: "Norway",
    symbol: "kr",
    currency: "NOK",
  },
  {
    id: 131,
    country: "Oman",
    symbol: "ر.ع.",
    currency: "OMR",
  },
  {
    id: 132,
    country: "Pakistan",
    symbol: "₨",
    currency: "PKR",
  },
  {
    id: 133,
    country: "Palau",
    symbol: "$",
    currency: "USD",
  },
  {
    id: 134,
    country: "Palestine State",
    symbol: "₪",
    currency: "ILS",
  },
  {
    id: 135,
    country: "Panama",
    symbol: "B/.",
    currency: "PAB",
  },
  {
    id: 136,
    country: "Papua New Guinea",
    symbol: "K",
    currency: "PGK",
  },
  {
    id: 137,
    country: "Paraguay",
    symbol: "₲",
    currency: "PYG",
  },
  {
    id: 138,
    country: "Peru",
    symbol: "S/.",
    currency: "PEN",
  },
  {
    id: 139,
    country: "Philippines",
    symbol: "₱",
    currency: "PHP",
  },
  {
    id: 140,
    country: "Poland",
    symbol: "zł",
    currency: "PLN",
  },
  {
    id: 141,
    country: "Portugal",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 142,
    country: "Qatar",
    symbol: "ر.ق",
    currency: "QAR",
  },
  {
    id: 143,
    country: "Romania",
    symbol: "lei",
    currency: "RON",
  },
  {
    id: 144,
    country: "Russia",
    symbol: "₽",
    currency: "RUB",
  },
  {
    id: 145,
    country: "Rwanda",
    symbol: "Fr",
    currency: "RWF",
  },
  {
    id: 146,
    country: "Saint Kitts and Nevis",
    symbol: "$",
    currency: "XCD",
  },
  {
    id: 147,
    country: "Saint Lucia",
    symbol: "$",
    currency: "XCD",
  },
  {
    id: 148,
    country: "Saint Vincent and the Grenadines",
    symbol: "$",
    currency: "XCD",
  },
  {
    id: 149,
    country: "Samoa",
    symbol: "T",
    currency: "WST",
  },
  {
    id: 150,
    country: "San Marino",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 151,
    country: "Sao Tome and Principe",
    symbol: "Db",
    currency: "STN",
  },
  {
    id: 152,
    country: "Saudi Arabia",
    symbol: "﷼",
    currency: "SAR",
  },
  {
    id: 153,
    country: "Senegal",
    symbol: "CFA",
    currency: "XOF",
  },
  {
    id: 154,
    country: "Serbia",
    symbol: "дин.",
    currency: "RSD",
  },
  {
    id: 155,
    country: "Seychelles",
    symbol: "₨",
    currency: "SCR",
  },
  {
    id: 156,
    country: "Sierra Leone",
    symbol: "Le",
    currency: "SLL",
  },
  {
    id: 157,
    country: "Singapore",
    symbol: "$",
    currency: "SGD",
  },
  {
    id: 158,
    country: "Slovakia",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 159,
    country: "Slovenia",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 160,
    country: "Solomon Islands",
    symbol: "$",
    currency: "SBD",
  },
  {
    id: 161,
    country: "Somalia",
    symbol: "Sh",
    currency: "SOS",
  },
  {
    id: 162,
    country: "South Africa",
    symbol: "R",
    currency: "ZAR",
  },
  {
    id: 163,
    country: "South Korea",
    symbol: "₩",
    currency: "KRW",
  },
  {
    id: 164,
    country: "South Sudan",
    symbol: "SSP",
    currency: "SSP",
  },
  {
    id: 165,
    country: "Spain",
    symbol: "€",
    currency: "EUR",
  },
  {
    id: 166,
    country: "Sri Lanka",
    symbol: "₨",
    currency: "LKR",
  },
  {
    id: 167,
    country: "Sudan",
    symbol: "ج.س.",
    currency: "SDG",
  },
  {
    id: 168,
    country: "Suriname",
    symbol: "$",
    currency: "SRD",
  },
  {
    id: 169,
    country: "Swaziland",
    symbol: "L",
    currency: "SZL",
  },
  {
    id: 170,
    country: "Sweden",
    symbol: "kr",
    currency: "SEK",
  },
  {
    id: 171,
    country: "Switzerland",
    symbol: "CHF",
    currency: "CHF",
  },
  {
    id: 172,
    country: "Syria",
    symbol: "ل.س",
    currency: "SYP",
  },
  {
    id: 173,
    country: "Tajikistan",
    symbol: "SM",
    currency: "TJS",
  },
  {
    id: 174,
    country: "Tanzania",
    symbol: "TSh",
    currency: "TZS",
  },
  {
    id: 175,
    country: "Thailand",
    symbol: "฿",
    currency: "THB",
  },
  {
    id: 176,
    country: "Timor-Leste",
    symbol: "$",
    currency: "USD",
  },
  {
    id: 177,
    country: "Togo",
    symbol: "CFA",
    currency: "XOF",
  },
  {
    id: 178,
    country: "Tonga",
    symbol: "T$",
    currency: "TOP",
  },
  {
    id: 179,
    country: "Trinidad and Tobago",
    symbol: "$",
    currency: "TTD",
  },
  {
    id: 180,
    country: "Tunisia",
    symbol: "د.ت",
    currency: "TND",
  },
  {
    id: 181,
    country: "Turkey",
    symbol: "₺",
    currency: "TRY",
  },
  {
    id: 182,
    country: "Turkmenistan",
    symbol: "m",
    currency: "TMT",
  },
  {
    id: 183,
    country: "Tuvalu",
    symbol: "$",
    currency: "AUD",
  },
  {
    id: 184,
    country: "Uganda",
    symbol: "USh",
    currency: "UGX",
  },
  {
    id: 185,
    country: "Ukraine",
    symbol: "₴",
    currency: "UAH",
  },
  {
    id: 186,
    country: "United Arab Emirates",
    symbol: "د.إ",
    currency: "AED",
  },
  {
    id: 187,
    country: "United Kingdom",
    symbol: "£",
    currency: "GBP",
  },
  {
    id: 188,
    country: "United States",
    symbol: "$",
    currency: "USD",
  },
  {
    id: 189,
    country: "Uruguay",
    symbol: "$",
    currency: "UYU",
  },
  {
    id: 190,
    country: "Uzbekistan",
    symbol: "so'm",
    currency: "UZS",
  },
  {
    id: 191,
    country: "Vanuatu",
    symbol: "VT",
    currency: "VUV",
  },
  {
    id: 192,
    country: "Venezuela",
    symbol: "Bs",
    currency: "VES",
  },
  {
    id: 193,
    country: "Vietnam",
    symbol: "₫",
    currency: "VND",
  },
  {
    id: 194,
    country: "Yemen",
    symbol: "﷼",
    currency: "YER",
  },
  {
    id: 195,
    country: "Zambia",
    symbol: "ZK",
    currency: "ZMW",
  },
  {
    id: 196,
    country: "Zimbabwe",
    symbol: "$",
    currency: "USD",
  },
];

const orderTypeOptions = [
  { value: "LOGO PACKAGE", label: "LOGO PACKAGE" },
  { value: "WEB PACKAGE", label: "WEB PACKAGE" },
  { value: "E-COMMERCE PACKAGE", label: "E-COMMERCE PACKAGE" },
];

const packageTypeOptions = [
  { value: "Hajj", label: "Hajj" },
  { value: "Umrah", label: "Umrah" },
];

const AddNewPackage = (props) => {
  const { visible, onCancel, currentPackage, mode, onOk } = props;
  console.log("currentPackage", currentPackage);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const descriptionRef = useRef();
  const { data } = useSelector((state) => state.user);

  console.log("data------>", data);

  const [state, setState] = useState({ ...initialState });
  const [newDescription, setNewDescription] = useState("");
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef("");

  useEffect(() => {
    /**
     * Set all feilds value if editing the coupon
     */
    if (mode?.current == "View Package" && visible) {
      setState(currentPackage);
      setFileList([{ url: currentPackage.image, id: 1 }]);
      form.setFieldsValue({
        "Package Name": currentPackage.packageName,
        Duration: currentPackage.packageDuration,
        "Package Type": currentPackage.packageType,
        Price: currentPackage.price,
        "Package Detail": currentPackage.packageDetail,
      });
    } else {
      setState({ ...initialState, packageIncludes: [] });
      setFileList([]);
    }
  }, [mode, visible]);

  let currencySymbol = currencies.find((x) => x.country == data.country);
  console.log("currencySymbol---->", currencySymbol);

  /**
   * Mutation and handler for adding new coupon
   */
  // const [addPackageMutation, { loading: addPackageLoading }] = useMutation(
  //   Mutations.ADD_NEW_PACKAGE
  // );

  const { isLoading: addPackageLoading, mutateAsync: addPackageMutation } = useMutation(
    Mutations.addPackageMutation
  );

  const handleAddNewPackage = async () => {
    try {
      let getResponse = await addPackageMutation({
        packageName: state.packageName,
        packageType: state.packageType,
        packageDuration: state.packageDuration,
        price: state.price,
        packageIncludes: state.packageIncludes,
        packageDetail: state.packageDetail,
        image: fileList[0]?.url,
      });
      dispatch({ type: ALL_PACKAGES.ADD_NEW, payload: getResponse.data.data });

      // props.getCouponData();
      closeModal();
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  // /**
  //  * Mutation and handler for updating coupon
  //  */
  // const [updatePackageMutation, { loading: updatePackageLoading }] = useMutation(
  //   Mutations.UPDATE_PACKAGE_BY_ID
  // );

  const handleViewPackage = async () => {
    // try {
    //   await updatePackageMutation({
    //     variables: {
    //       packageId: currentPackage._id,
    //       packageType: state.packageType,
    //       orderType: state.orderType,
    //       description: state.description,
    //       rushDeliveryCharges: state.rushDeliveryCharges,
    //       originalPrice: state.originalPrice,
    //       price: state.price,
    //       discount: `${Math.floor(100 - (state.price * 100) / state.originalPrice)}`,
    //       slug: state.slug,
    //     },
    //   });
    //   window.location.reload();
    //   closeModal();
    // } catch (err) {
    //   message.error(errorHandler(err));
    // }
  };

  const handleChange = (e) => {
    if (e.hasOwnProperty("target")) {
      const { name, value } = e.target;
      setState((prev) => ({ ...prev, [name]: value }));
    } else {
      const { formattedValue, value, floatValue } = e;
      setState((prev) => ({ ...prev, [inputRef.current]: formattedValue }));
    }
  };

  const closeModal = () => {
    setFileList([]);
    setState({ ...initialState }); // Reset State
    form.resetFields(); // Empty form feilds
    onCancel(); // Close the modal
  };

  // const formatCurrency = (amount) => {
  //   console.log(
  //     "converted------>",
  //     parseFloat(amount).replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
  //   );
  //   setState({
  //     ...state,
  //     price: parseFloat(amount).replace(/(\d)(?=(\d{3})+\.)/g, "$1,"),
  //   });
  //   return parseFloat(amount).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  // };

  return (
    <Modal
      title={mode?.current == "View Package" ? "Package Details" : "Add Package"}
      visible={visible}
      onCancel={closeModal}
      footer={null}
      centered
    >
      <StyledContent>
        <Form
          form={form}
          onFinish={
            mode?.current == "View Package" ? handleViewPackage : handleAddNewPackage
          }
          // validateTrigger="onFinish"
        >
          <Row justify="space-between" gutter={[24, 12]}>
            <Col span={24}>
              <h4 className="form-label">*Package Banner Image</h4>
              <Form.Item name="Package Name" rules={[{ required: true }]}>
                <BannerImageUploader
                  uploadLength={1}
                  uploadPreset={"travel_agent_packages"}
                  loading={loading}
                  setLoading={setLoading}
                  fileList={fileList}
                  setFileList={setFileList}
                  viewDeleteIcon={mode?.current}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <h4 className="form-label">*Package Name</h4>
              <Form.Item name="Package Name" rules={[{ required: true }]}>
                <InputWrapper
                  readOnly={mode.current == "View Package" ? true : false}
                  maxLength={20}
                  name="packageName"
                  value={state.packageName}
                  placeholder="Package Name"
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <h4 className="form-label">*Package Type</h4>
              <Form.Item name="Package Type" rules={[{ required: true }]}>
                <SelectWrapper
                  style={{
                    pointerEvents: mode.current == "View Package" ? "none" : "auto",
                  }}
                  name="packageType"
                  options={packageTypeOptions}
                  onChange={(val) => {
                    setState({ ...state, packageType: val });
                  }}
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <h4 className="form-label">*Duration (In days)</h4>
              <Form.Item
                name="Duration"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                {/* <InputWrapper
                  readOnly={mode.current == "View Package" ? true : false}
                  // maxLength={4}
                  type={"number"}
                  name="packageDuration"
                  value={state.packageDuration}
                  placeholder="40"
                  onChange={handleChange}
                  suffix={<div>Day(s)</div>}
                /> */}

                <NumericFormat
                  className="numeric-formatt"
                  style={{
                    height: 40,
                    borderRadius: 4,
                    border: "1px solid #d1d1d1",
                    width: "100%",
                    padding: "0px 12px",
                  }}
                  disabled={mode.current == "View Package" ? true : false}
                  placeholder="50 Days"
                  value={state.packageDuration}
                  displayType="input"
                  thousandSeparator={false}
                  suffix=" Days"
                  // customInput={Input}
                  onValueChange={(e) => {
                    inputRef.current = "packageDuration";
                    handleChange(e);
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <h4 className="form-label">*Amount</h4>
              <Form.Item name="Price" rules={[{ required: true }]}>
                {/* <InputWrapper
                  readOnly={mode.current == "View Package" ? true : false}
                  maxLength={20}
                  name="price"
                  value={state.price}
                  placeholder="600,000"
                  onChange={(e) => {
                    console.log("e--->", e.target.value);
                    handleChange(e);
                    formatCurrency(e);
                  }}
                  prefix={<div>$</div>}
                /> */}

                <NumericFormat
                  className="numeric-formatt"
                  style={{
                    height: 40,
                    borderRadius: 4,
                    border: "1px solid #d1d1d1",
                    width: "100%",
                    padding: "0px 12px",
                  }}
                  disabled={mode.current == "View Package" ? true : false}
                  placeholder={`${currencySymbol?.symbol} 600,000`}
                  value={state.price}
                  displayType="input"
                  thousandSeparator={true}
                  prefix={`${currencySymbol?.symbol} `}
                  // customInput={Input}
                  onValueChange={(e) => {
                    inputRef.current = "price";
                    handleChange(e);
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <h4 className="form-label">*Package Description</h4>
              <Form.Item name="Package Detail" rules={[{ required: true }]}>
                <InputWrapper.TextArea
                  rows={4}
                  readOnly={mode.current == "View Package" ? true : false}
                  maxLength={1000}
                  name="packageDetail"
                  value={state.packageDetail}
                  placeholder="Package Description"
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <h4 className="form-label">*Package Includes</h4>

              <div className="description-container" ref={descriptionRef}>
                {state.packageIncludes?.map((item, index) => (
                  <div
                    className="description-wrapper"
                    style={{
                      justifyContent:
                        mode.current == "View Package" ? "center" : "space-between",
                    }}
                  >
                    <div key={index} className="description">
                      {item}
                    </div>
                    {/* <div
                      title="Remove"
                      onClick={() => {
                        const i = state.description.indexOf(item);
                        console.log("Index", i);
                        state.description.splice(i, 1);
                        setState({ ...state });
                      }}
                      className="delete-description"
                    > */}
                    <HiX
                      className="delete-description"
                      title="Remove"
                      onClick={() => {
                        const i = state.packageIncludes.indexOf(item);
                        console.log("Index", i);
                        state.packageIncludes.splice(i, 1);
                        setState({ ...state });
                      }}
                      style={{
                        display: mode.current == "View Package" ? "none" : "flex",
                      }}
                    />
                    {/* </div> */}
                  </div>
                ))}
              </div>

              <InputWrapper
                style={{
                  marginTop: 10,
                  display: mode.current == "View Package" ? "none" : "flex",
                }}
                placeholder="Hotel Accomodation"
                value={newDescription}
                suffix={
                  <div
                    className="add-btn"
                    title="Add"
                    onClick={() => {
                      if (newDescription !== "") {
                        console.log("newDescription", newDescription);
                        state.packageIncludes.push(newDescription);
                        setState({ ...state });
                        descriptionRef.current.scrollTo({
                          top: (state.packageIncludes.length + 1) * 48,
                          behavior: "smooth",
                        });
                        setNewDescription("");
                      }
                    }}
                  >
                    Add
                  </div>
                }
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setNewDescription(e.target.value);
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value !== "") {
                      e.preventDefault();
                      state.packageIncludes.push(e.target.value);
                      console.log("state---->", state);
                      setState({ ...state });
                      descriptionRef.current.scrollTo({
                        top: (state.packageIncludes.length + 1) * 48,
                        behavior: "smooth",
                      });
                      e.target.value = "";
                      setNewDescription("");
                    }
                  }
                }}
              />
            </Col>
          </Row>

          <br />

          <Row
            justify="end"
            align="middle"
            style={{ display: mode.current == "View Package" ? "none" : "flex" }}
          >
            <Col span={7} style={{ paddingLeft: "10px" }}>
              <Button
                style={{
                  outline: "none",
                  border: "1px solid #ffbe04",
                  backgroundColor: "#fff",
                  color: "#000",
                }}
                block
                shape="round"
                type="danger"
                size="default"
                onClick={closeModal}
              >
                Cancel
              </Button>
            </Col>
            <Col span={7} style={{ paddingLeft: "10px" }}>
              <Button
                block
                shape="round"
                type="primary"
                size="default"
                style={{ outline: "none", border: "none", backgroundColor: "#ffbe04" }}
                htmlType="submit"
                onClick={onOk}
                loading={addPackageLoading}
              >
                Create
              </Button>
            </Col>
          </Row>
        </Form>
      </StyledContent>
    </Modal>
  );
};

AddNewPackage.defaultProps = {
  onCancel: () => {},
  currentPackage: { _id: null },
  onOk: () => {},
  mode: "Add Package",
};

export default AddNewPackage;
