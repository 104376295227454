import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { TableButton } from "components/buttons";
import UserDetailModal from "components/modals/userDetailModal";
import { MdOutlineLocationOff, MdOutlineLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import moment from "moment";
import { Tooltip, message } from "antd";
import { Queries, useMutation, errorHandler, Mutations } from "apis/config";
import AddNewPackage from "components/modals/addPackageModal";
import { BiTrash } from "react-icons/bi";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { ALL_PACKAGES } from "redux/constants";

const StyledDiv = styled.div`
  cursor: pointer;
  margin: 0px 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  :hover {
    background-color: #95838329;
  }
`;

const Packages = (props) => {
  const { loading } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  // const [allPackages, setAllPackages] = useState([]);
  const [userModal, toggleUserModal] = useState(false);
  const [addPackageModal, toggleAddPackageModal] = useState(false);

  const { allPackages } = useSelector((state) => state.packages);
  const packages = useRef();
  const mode = useRef("View Package");

  const { mutateAsync: getCompanyPackages, isLoading: getPackagesLoading } = useMutation(
    Queries.getCompanyPackages
  );

  const { isLoading: deleteLoading, mutateAsync: deletePackageMutation } = useMutation(
    Mutations.deletePackageMutation
  );

  useEffect(() => {
    getCompanyPackages()
      .then(({ data }) => {
        dispatch({ type: ALL_PACKAGES.STORE, payload: data.data });
      })
      .catch((e) => message.error(errorHandler(e)));
  }, []);

  const handleDelete = async (packageId) => {
    try {
      let getResponse = await deletePackageMutation({
        packageId: packageId,
      });
      dispatch({ type: ALL_PACKAGES.STORE, payload: getResponse.data.data });
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  return (
    <>
      <UserDetailModal
        title="User details"
        visible={userModal}
        onCancel={() => toggleUserModal(false)}
        data={packages.current}
      />
      {addPackageModal && (
        <AddNewPackage
          mode={mode}
          currentPackage={packages.current}
          visible={addPackageModal}
          onCancel={() => toggleAddPackageModal(false)}
        />
      )}
      <div>
        <ContentHeader
          title="Packages"
          onAdd={() => {
            if (allPackages.length == 2) {
              message.error("You can not create more than 2 packages");
            } else {
              mode.current = "Add Package";
              toggleAddPackageModal(true);
            }
          }}
        />
        <TableWrapper
          data={allPackages}
          loading={getPackagesLoading}
          columns={[
            {
              Header: "Package Name",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {original.packageName}
                  </div>
                );
              },
              sortable: false,
            },
            {
              Header: "Package For",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {original.packageType}
                  </div>
                );
              },
              sortable: false,
            },
            {
              Header: "Amount",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {original.price}
                  </div>
                );
              },
              sortable: false,
            },
            {
              Header: "Duration",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {original.packageDuration}
                  </div>
                );
              },
              sortable: false,
            },

            {
              Header: "Status",
              Cell: ({ original }) => {
                if (original.status == 0) {
                  return (
                    <div style={{ textAlign: "center", width: "100%", color: "orange" }}>
                      Pending for Approval
                    </div>
                  );
                } else if (original.status == 1) {
                  return (
                    <div style={{ textAlign: "center", width: "100%", color: "#008000" }}>
                      Approved
                    </div>
                  );
                } else {
                  return (
                    <div style={{ textAlign: "center", width: "100%", color: "red" }}>
                      Rejected
                    </div>
                  );
                }
              },
              sortable: false,
            },
            // {
            //   Header: "Email",
            //   Cell: ({ original }) => {
            //     return (
            //       <div style={{ textAlign: "left", width: "100%" }}>{original.email}</div>
            //     );
            //   },
            //   sortable: false,
            // },
            // {
            //   Header: "Phone",
            //   Cell: ({ original }) => {
            //     return (
            //       <div style={{ textAlign: "center", width: "100%" }}>
            //         {original.phoneNumber}
            //       </div>
            //     );
            //   },
            //   sortable: false,
            // },
            // {
            //   Header: "Training Type",
            //   Cell: ({ original }) => {
            //     return (
            //       <div style={{ textAlign: "center", width: "100%" }}>
            //         {original.trainingType}
            //       </div>
            //     );
            //   },
            //   sortable: false,
            // },

            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TableButton
                      onClick={() => {
                        packages.current = original;
                        mode.current = "View Package";
                        toggleAddPackageModal(true);
                      }}
                    >
                      view
                    </TableButton>

                    <StyledDiv
                      onClick={() => {
                        packages.current = original;
                        if (!deleteLoading) {
                          handleDelete(original._id);
                        }
                      }}
                    >
                      {deleteLoading && original._id == packages.current._id ? (
                        <LoadingOutlined
                          title="Loading..."
                          style={{ fontSize: 24, color: "#135bed" }}
                        />
                      ) : (
                        <BiTrash title="Delete" style={{ fontSize: 24, color: "red" }} />
                      )}
                    </StyledDiv>
                  </div>
                );
              },
              sortable: false,
            },
            // {
          ]}
        />
      </div>
    </>
  );
};

export default Packages;
