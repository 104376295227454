export const errorHandler = (error) => {
  // console.log("-e-> GraphQLErrors", error.graphQLErrors);
  // console.log("-e-> NetworkError", error.networkError);
  // console.log("-e-> ExtraInfo", error.extraInfo);
  // console.log("-e-> Response", error.response);
  // console.log("-e-> Message", error.message);

  // console.log("response", error.response);
  // console.log("isAxiosError", error.isAxiosError);
  // console.log("toJSON", error.toJSON());
  // console.log("networkError", error.networkError);
  // console.log("message", error.message);

  if (error.response) {
    return error.response.data.error;
  }
  // else if (error.networkError) {
  //   return `${error.networkError}`;
  // }
  else if (error.message) {
    return `${error.message}`;
  } else {
    return "Something went wrong";
  }
};
