import { ALL_PACKAGES } from "redux/constants";

const initialState = { allPackages: [] };

const allPackagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_PACKAGES.STORE: {
      const { payload } = action;
      return { ...state, allPackages: payload };
    }

    case ALL_PACKAGES.CHANGE_STATUS: {
      const { adminId, status } = action.payload;
      const currentAdmin = state.allPackages.find((admin) => admin._id === adminId);
      currentAdmin.status = status;
      return { ...state };
    }

    case ALL_PACKAGES.EDIT_DATA: {
      const { payload } = action;
      const currentAdminIndex = state.allPackages.findIndex(
        (user) => user._id === payload._id
      );
      state.allPackages.splice(currentAdminIndex, 1, payload);

      return { ...state };
    }

    case ALL_PACKAGES.ADD_NEW: {
      const { payload } = action;

      return { ...state, allPackages: [payload, ...state.allPackages] };
    }

    default:
      return state;
  }
};

export default allPackagesReducer;
