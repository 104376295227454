import { useRef, useState } from "react";
import { Form, message, Row, Col, Steps, DatePicker } from "antd";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper, PhoneInputWrapper, SelectWrapper } from "components/input";
import styled from "styled-components";
import { isValidPhoneNumber } from "react-phone-number-input";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import { errorHandler, useMutation, Mutations } from "apis/config";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { ALL_USERS } from "redux/constants";
import { countryList } from "helpers/countries";

const { Step } = Steps;

const initialState = {
  instructorId: "",
  fullName: "",
  email: "",
  city: "",
  dob: "",
  password: "",
  phoneNumber: "",
  phoneError: "",
  country: "",
  trainingType: "",
  gender: "",
  // Flight State
  flightNumber: "",
  departureDate: "",
  arrivalDate: "",
  flyingFrom: "",
  flyingTo: "",
  // Hotel state
  arrivalDateMakkah: "",
  hotelAddressMakkah: "",
  arrivalDateMadina: "",
  hotelAddressMadina: "",
  minnaTentNumber: "",
};

const AddNewUser = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [state, setState] = useState({ ...initialState });
  const userData = useRef();

  const { selectOptions } = useSelector((state) => state.instructors);

  const [form] = Form.useForm();
  // Step - 1
  const { mutateAsync: createNewUser, isLoading: createNewUserLoading } = useMutation(
    Mutations.createNewUser
  );
  // Step - 2
  const { mutateAsync: addHotelDetails, isLoading: addHotelDetailsLoading } = useMutation(
    Mutations.addHotelDetails
  );
  // Step - 3
  const { mutateAsync: addFlightDetails, isLoading: addFlightDetailsLoading } =
    useMutation(Mutations.addFlightDetails);

  const handleCreateUser = async () => {
    try {
      if (isValidPhoneNumber(state.phoneNumber)) {
        const { data: newUserData } = await createNewUser({
          instructorId: state.instructorId,
          fullName: state.fullName,
          email: state.email,
          city: state.city,
          dob: moment(state.dob).format("YYYY-MM-DD"),
          password: state.password,
          phoneNumber: state.phoneNumber,
          country: state.country,
          trainingType: state.trainingType,
          gender: state.gender,
          lastLicenseUsed: moment().format("DD-MMM-YYYY HH:mm A"),
        });
        console.log("New User Data", newUserData.data);
        userData.current = newUserData.data;
        dispatch({ type: ALL_USERS.INSERT, payload: newUserData.data });
        setStep(1);
      }
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const handleHotelDetails = async () => {
    try {
      await addHotelDetails({
        userId: userData.current._id,
        arrivalDateMakkah: moment(state.arrivalDateMakkah).format("YYYY-MM-DD"),
        hotelAddressMakkah: state.hotelAddressMakkah,
        arrivalDateMadina: moment(state.arrivalDateMadina).format("YYYY-MM-DD"),
        hotelAddressMadina: state.hotelAddressMadina,
        minnaTentNumber: state.minnaTentNumber,
      });
      setStep(2);
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const handleFlightDetails = async () => {
    try {
      const { data } = await addFlightDetails({
        userId: userData.current._id,
        flightNumber: state.flightNumber,
        departureDate: moment(state.departureDate).format("YYYY-MM-DD"),
        arrivalDate: moment(state.arrivalDate).format("YYYY-MM-DD"),
        flyingFrom: state.flyingFrom,
        flyingTo: state.flyingTo,
      });
      history.push("/users");
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const countryNames = countryList
    .map((item) => {
      return Object.keys(item).map((country) => {
        return { label: country, value: country };
      });
    })
    .flat();

  let cityNames = [];
  if (state.country !== "") {
    cityNames = countryList
      .map((item) => {
        return item[state?.country]?.map((city) => {
          return { label: city, value: city };
        });
      })
      .flat();
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const steps = [
    {
      title: "Create new user",
      content: (
        <StyledContent>
          <Form form={form} onFinish={handleCreateUser} validateTrigger="onFinish">
            <div className="white-background">
              <Row gutter={[32, 0]}>
                <Col span={8}>
                  <div className="label">Instructor*</div>
                  <Form.Item name="Instructor" rules={[{ required: true }]}>
                    <SelectWrapper
                      size="large"
                      options={selectOptions}
                      onChange={(val) => setState({ ...state, instructorId: val })}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Full Name*</div>
                  <Form.Item name="Full Name" rules={[{ required: true }]}>
                    <InputWrapper
                      name="fullName"
                      placeholder="Full Name"
                      value={state.fullName}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Email*</div>
                  <Form.Item name="Email" rules={[{ type: "email", required: true }]}>
                    <InputWrapper
                      name="email"
                      placeholder="someone@example.com"
                      value={state.email}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Country*</div>
                  <Form.Item name="Country" rules={[{ required: true }]}>
                    <SelectWrapper
                      showSearch={true}
                      size="large"
                      value={state.country}
                      options={countryNames}
                      onChange={(val) => setState({ ...state, country: val })}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">City*</div>
                  <Form.Item name="City" rules={[{ required: true }]}>
                    <SelectWrapper
                      disabled={state.country == "" ? true : false}
                      placeholder={state.country == "" ? "Select country" : "Select city"}
                      value={state.city}
                      showSearch={true}
                      size="large"
                      options={cityNames}
                      onChange={(val) => setState({ ...state, city: val })}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Date of birth*</div>
                  <Form.Item name="Date of birth" rules={[{ required: true }]}>
                    <DatePicker
                      value={state.dob}
                      style={{
                        height: 40,
                        width: "100%",
                        borderRadius: 4,
                      }}
                      onChange={(e) => setState({ ...state, dob: e })}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Gender*</div>
                  <Form.Item name="Gender" rules={[{ required: true }]}>
                    <SelectWrapper
                      value={state.gender}
                      size="large"
                      options={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" },
                      ]}
                      onChange={(val) => setState({ ...state, gender: val })}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Training type*</div>
                  <Form.Item name="Training type" rules={[{ required: true }]}>
                    <SelectWrapper
                      value={state.trainingType}
                      size="large"
                      options={[
                        { label: "Hajj", value: "hajj" },
                        { label: "Umrah", value: "umrah" },
                      ]}
                      onChange={(val) => setState({ ...state, trainingType: val })}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Password*</div>
                  <Form.Item name="Password" rules={[{ required: true, min: 3 }]}>
                    <InputWrapper.Password
                      name="password"
                      value={state.password}
                      placeholder="Password"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Phone Number*</div>
                  <PhoneInputWrapper
                    name="phoneNumber"
                    autoComplete="new-password"
                    value={state.phoneNumber}
                    placeholder="Phone Number"
                    onChange={handleChange}
                    error={state.phoneError}
                  />
                </Col>
                <Col span={8} offset={16}>
                  <ButtonWrapper
                    htmlType="submit"
                    style={{ height: 40, width: "100%", marginTop: 16 }}
                    loading={createNewUserLoading}
                    onClick={() => {
                      if (!isValidPhoneNumber(state.phoneNumber)) {
                        setState({
                          ...state,
                          phoneError: "Invalid phone number",
                        });
                      }
                    }}
                  >
                    Create
                  </ButtonWrapper>
                </Col>
              </Row>
            </div>
          </Form>
        </StyledContent>
      ),
    },
    {
      title: "Add hotel details",
      content: (
        <StyledContent>
          <Form onFinish={handleHotelDetails} validateTrigger="onFinish">
            <div className="white-background">
              <Row gutter={[32, 0]} align="middle">
                <Col span={8}>
                  <div className="label">Arrival date: Makkah</div>
                  <Form.Item name="Arrival date: Makkah">
                    <DatePicker
                      value={state.arrivalDateMakkah}
                      style={{
                        height: 40,
                        width: "100%",
                        borderRadius: 4,
                      }}
                      onChange={(e) => setState({ ...state, arrivalDateMakkah: e })}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Hotel address: Makkah</div>
                  <Form.Item name="Hotel address: Makkah">
                    <InputWrapper
                      name="hotelAddressMakkah"
                      value={state.hotelAddressMakkah}
                      placeholder="Makkah address"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Arrival date: Madina</div>
                  <Form.Item name="Arrival date: Madina">
                    <DatePicker
                      value={state.arrivalDateMadina}
                      style={{
                        height: 40,
                        width: "100%",
                        borderRadius: 4,
                      }}
                      onChange={(e) => setState({ ...state, arrivalDateMadina: e })}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Hotel address: Madina</div>
                  <Form.Item name="Hotel address: Madina">
                    <InputWrapper
                      name="hotelAddressMadina"
                      value={state.hotelAddressMadina}
                      placeholder="Madina address"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Minna tent number</div>
                  <Form.Item name="Minna tent number">
                    <InputWrapper
                      name="minnaTentNumber"
                      value={state.minnaTentNumber}
                      placeholder="Tent number"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="end" gutter={[32, 0]}>
                    <Col>
                      <div className="skip-button" onClick={handleHotelDetails}>
                        Skip for later
                      </div>
                    </Col>
                    <Col span={8}>
                      <ButtonWrapper
                        htmlType="submit"
                        style={{ height: 40, width: "100%" }}
                        loading={addHotelDetailsLoading}
                      >
                        Next
                      </ButtonWrapper>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        </StyledContent>
      ),
    },
    {
      title: "Add flight details",
      content: (
        <StyledContent>
          <Form onFinish={handleFlightDetails} validateTrigger="onFinish">
            <div className="white-background">
              <Row gutter={[32, 0]} align="middle">
                <Col span={8}>
                  <div className="label">Flight number</div>
                  <Form.Item name="Flight number">
                    <InputWrapper
                      name="flightNumber"
                      value={state.flightNumber}
                      placeholder="Flight number"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Departure date</div>
                  <Form.Item name="Departure date">
                    <DatePicker
                      value={state.departureDate}
                      style={{
                        height: 40,
                        width: "100%",
                        borderRadius: 4,
                      }}
                      onChange={(e) => setState({ ...state, departureDate: e })}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Arrival date</div>
                  <Form.Item name="Arrival date">
                    <DatePicker
                      value={state.arrivalDate}
                      style={{
                        height: 40,
                        width: "100%",
                        borderRadius: 4,
                      }}
                      onChange={(e) => setState({ ...state, arrivalDate: e })}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Flying from</div>
                  <Form.Item name="Flying from">
                    <InputWrapper
                      name="flyingFrom"
                      value={state.flyingFrom}
                      placeholder="Flying from"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div className="label">Flying to</div>
                  <Form.Item name="Flying to">
                    <InputWrapper
                      name="flyingTo"
                      value={state.flyingTo}
                      placeholder="Flying to"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="end" gutter={[32, 0]}>
                    <Col>
                      <div className="skip-button" onClick={handleFlightDetails}>
                        Skip for later
                      </div>
                    </Col>
                    <Col span={8}>
                      <ButtonWrapper
                        htmlType="submit"
                        style={{ height: 40, width: "100%" }}
                        loading={addFlightDetailsLoading}
                      >
                        Finish
                      </ButtonWrapper>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        </StyledContent>
      ),
    },
  ];

  return (
    <>
      <div style={{ width: "100%", padding: "0px 20%" }}>
        <Steps current={step}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </div>
      <StyledContent>{steps[step].content}</StyledContent>
    </>
  );
};

export default AddNewUser;

const StyledContent = styled.div`
  margin-top: 32px;
  .label {
    color: #786161;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .white-background {
    background-color: #fff;
    padding: 60px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%) !important;
    min-height: 70vh;
    border-radius: 6px;
  }

  .skip-button {
    color: #568eff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-right: 16px;
  }
`;
