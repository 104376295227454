import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { errorHandler } from "helpers/errorHandler";
import { Queries } from "./queries";
import { Mutations } from "./mutations";

const userModule = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL + "/api/user",
  // baseURL: `http://192.168.88.10:5000/api/user`,
});

const companyModule = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL + "/api/company",
  // baseURL: `http://192.168.88.10:5000/api/company`,
});

export {
  useMutation,
  useQuery,
  errorHandler,
  userModule,
  companyModule,
  Queries,
  Mutations,
};
