import React, { useState } from "react";
import { InputWrapper } from "../components/input";
import BannerImage from "images/loginTopImage.svg";
import { Form, message } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { BiLeftArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { ButtonWrapper } from "components/buttons";
import { errorHandler, useMutation, Mutations } from "apis/config";
import { useHistory } from "react-router-dom";
import { StyledContent } from "./Login";

const ForgotPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  /**
   * Muatation and handler for forgot password
   */
  const { isLoading, mutateAsync } = useMutation(Mutations.forgotPassword);

  const handleForgotPassword = async () => {
    try {
      await mutateAsync({ email });
      history.push("/reset-password", { email });
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  return (
    <StyledContent>
      <div>
        <Link to="/">
          <div className="back-icon">
            <BiLeftArrowAlt fontSize={20} fill="#000" />
          </div>
        </Link>

        <div className="card-wrapper">
          <div className="card-banner">
            <div style={{ alignSelf: "flex-start", margin: "24px 0 0 32px" }}>
              <div className="banner-wrapper">
                <div style={{ fontWeight: 600, fontSize: 18 }}>Don't worry</div>
                <p style={{ fontWeight: 500, fontSize: 13, marginTop: 6 }}>
                  Resetting your password is easy, enter the email address you registered
                  on winq
                </p>
              </div>
            </div>
            <div>
              <img src={BannerImage} alt="Logo" width={240} />
            </div>
          </div>
          <div style={{ padding: "48px 32px 32px 32px" }}>
            <Form>
              <div>
                <h4 style={{ textAlign: "left", marginBottom: 12, fontSize: 16 }}>
                  Email
                </h4>
                <InputWrapper
                  required
                  placeholder="abc@xyz.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <ButtonWrapper
                style={{ marginTop: 24, height: 40 }}
                loading={isLoading}
                htmlType="submit"
                onClick={handleForgotPassword}
              >
                Reset Password
                {!isLoading ? <ArrowRightOutlined style={{ marginLeft: 8 }} /> : null}
              </ButtonWrapper>
            </Form>
          </div>
        </div>
      </div>
    </StyledContent>
  );
};

export default ForgotPassword;
