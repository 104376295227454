import { ALL_USERS } from "../constants";

const initialState = { allUsers: [] };

const allUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_USERS.STORE: {
      const { payload } = action;
      return { ...state, allUsers: payload };
    }

    case ALL_USERS.INSERT: {
      const { payload } = action;
      return { ...state, allUsers: [payload, ...state.allUsers] };
    }

    default:
      return state;
  }
};

export default allUsersReducer;
