import { useEffect, useState } from "react";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper } from "components/input";
import styled from "styled-components";
import { Row, Col, message, Form, Skeleton } from "antd";
import { SendOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { useMutation, Mutations, Queries, errorHandler } from "apis/config";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import AdminImage from "images/labbaik.png";
import MessageLoading from "components/skeleton/messageLoading";

const MessageThread = ({ userRole }) => {
  const params = useParams();
  const [recipient, setRecipient] = useState({
    fullName: "",
    pictureURL: "",
    companyName: "",
  });
  const [messageText, setMessageText] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  console.log("allMessages", allMessages);
  const { mutateAsync: getMessagesForUser, isLoading: getMessageLoadingUser } =
    useMutation(Queries.getMessagesForUser);

  const { mutateAsync: getMessagesThreadOfUser } = useMutation(
    Queries.getMessagesThreadOfUser
  );

  // Used by user to send message to sale/admin
  const { mutateAsync: getAllMyMessagesToSale, isLoading: getMessageLoading } =
    useMutation(Queries.getAllMyMessagesToSale);

  // Used by user to send message to company/admin
  const { mutateAsync: sendMessageForUser, isLoading: sendLoadingForUser } = useMutation(
    Mutations.sendMessageForUser
  );

  // Used by company to send messages to user
  const { mutateAsync: sendMessageToUser, isLoading: sendMessageToUserLoading } =
    useMutation(Mutations.sendMessageToUser);

  // Used by company to send messages to sale
  const { mutateAsync: sendMessageToSale, isLoading: sendMessageToSaleLoading } =
    useMutation(Mutations.sendMessageForSale);

  const sendMessage = async () => {
    try {
      const { data } = await sendMessageForUser({
        message: messageText,
        to: params.messageId,
      });
      const isCompany = params.messageId == "messageToCompany";
      const mappedData = mapMessageDataForUser(data.data, isCompany);
      setAllMessages(mappedData);
      setMessageText("");
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const sendMessageForCompany = async () => {
    try {
      if (userRole == 2 && params.userId == "messageToSales") {
        const { data } = await sendMessageToSale({
          message: messageText,
          to: params.userId,
        });
        const isCompany = params.userId == "messageToSales";
        const mappedData = mapMessageDataForUser(data.data, isCompany);
        setAllMessages(mappedData);
        setMessageText("");
      } else {
        const { data } = await sendMessageToUser({
          message: messageText,
          userId: params.userId,
        });
        const mappedData = mapMessagesForCompany(data.data);
        setAllMessages(mappedData);
        setMessageText("");
      }
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  useEffect(() => {
    // For user
    if (userRole == 1) {
      const to =
        params.messageId == "messageToCompany"
          ? "getAllMyMessagesToCompany"
          : "getAllMyMessagesToAdmin";
      const isCompany = params.messageId == "messageToCompany";
      getMessagesForUser({ to })
        .then(({ data }) => {
          const mappedData = mapMessageDataForUser(data.data, isCompany);
          const threadRecipient = findRecipient(data.data);
          if (isCompany) {
            setRecipient({
              fullName: threadRecipient.companyName,
              pictureURL: threadRecipient.pictureURL,
            });
          } else {
            setRecipient({ fullName: "Admin", pictureURL: threadRecipient.pictureURL });
          }
          setAllMessages(mappedData);
        })
        .catch((err) => message.error(errorHandler(err)));
    }
    // For company
    if (userRole == 2) {
      if (params.userId == "messageToSales") {
        getAllMyMessagesToSale()
          .then(({ data }) => {
            setRecipient({ fullName: "Admin", pictureURL: data.data.adminId.pictureURL });
            const isCompany = params.userId == "messageToSales";
            const mappedData = mapMessageDataForUser(data.data, false);
            setAllMessages(mappedData);
          })
          .catch((err) => message.error(errorHandler(err)));
      } else {
        const userId = params.userId;
        getMessagesThreadOfUser({ userId })
          .then(({ data }) => {
            setRecipient(data.data.userId);
            const mappedData = mapMessagesForCompany(data.data);
            setAllMessages(mappedData);
          })
          .catch((err) => message.error(errorHandler(err)));
      }
    }
  }, [userRole, params]);

  return (
    <StyledThread>
      <div className="white-background">
        <section className="thread-heading">
          {getMessageLoading || getMessageLoadingUser ? (
            <Skeleton.Image active={true} />
          ) : (
            <>
              <img className="profile-image" src={recipient?.pictureURL?.url} />
              <div className="name">{recipient?.fullName}</div>
            </>
          )}
        </section>

        <section style={{ height: "calc(100vh - 380px)", overflow: "auto" }}>
          {getMessageLoading || getMessageLoadingUser ? (
            <MessageLoading />
          ) : (
            <>
              {allMessages.map((item, index) => (
                <Row key={index} className="message-wrapper">
                  {item.sender ? (
                    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                      <Col span={1} style={{ display: "flex", justifyContent: "center" }}>
                        <img src={recipient?.pictureURL?.url} className="user-profile" />
                      </Col>
                      <Col span={23}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="name">{item.fullName}</div>
                          <div className="little-dot"></div>
                          <div className="message-time">
                            {moment(item.createdAt).format("ddd, DD MMM YY")}
                          </div>
                        </div>
                        <p style={{ borderTopLeftRadius: 0 }} className={`message-body`}>
                          {item.message}
                        </p>
                      </Col>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Col span={23}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div style={{ textAlign: "end" }} className="message-time">
                            {moment(item.createdAt).format("ddd, DD MMM YY")}
                          </div>
                          <div className="little-dot"></div>
                          <div className="name">{item.fullName}</div>
                        </div>
                        <p
                          style={{ float: "right", borderTopRightRadius: 0 }}
                          className={`message-body blue`}
                        >
                          {item.message}
                        </p>
                      </Col>
                      <Col span={1} style={{ display: "flex", justifyContent: "center" }}>
                        <img src={item.pictureURL.url} className="user-profile" />
                      </Col>
                    </div>
                  )}
                </Row>
              ))}
            </>
          )}
        </section>

        {/* <div
          style={{ height: 88, backgorundColor: "#fff", borderTop: "1px solid #d9d9d9" }}
        > */}
        <div className="reply">
          <InputWrapper
            placeholder="Type your message"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                userRole == 1 ? sendMessage() : sendMessageForCompany();
              }
            }}
          />

          <ButtonWrapper
            size="large"
            style={{ width: 200, marginLeft: 12 }}
            disabled={
              sendMessageToUserLoading ||
              sendLoadingForUser ||
              messageText.trim().length === 0
            }
            onClick={userRole == 1 ? sendMessage : sendMessageForCompany}
          >
            Send{" "}
            {sendLoadingForUser ||
            sendMessageToUserLoading ||
            sendMessageToSaleLoading ? (
              <LoadingOutlined style={{ marginLeft: 16 }} />
            ) : (
              <SendOutlined style={{ marginLeft: 16 }} />
            )}
          </ButtonWrapper>
        </div>
        {/* </div> */}
      </div>
    </StyledThread>
  );
};

const mapState = (state) => ({
  userRole: state.user.data.userRole,
});

export default connect(mapState)(MessageThread);

const StyledThread = styled.div`
  background-color: #fff;
  min-height: 600px;
  position: relative;

  .white-background {
    background-color: #fff;
    padding: 10px 60px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%) !important;
    // min-height: 60vh;
    border-radius: 6px;
  }

  .thread-heading {
    // height: 72px;
    display: flex;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #d9d9d9;
    font-size: 32px;
    font-weight: 600;

    .profile-image {
      height: 64px;
      width: 64px;
      border-radius: 50%;
      margin-right: 12px;
    }
  }

  .message-wrapper {
    margin: 12px;
    .user-profile {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      margin: 8px 12px 0 12px;
    }
    .name {
      font-weight: 600;
    }
    .little-dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #000000b0;
      margin: 0 6px;
    }
    .message-body {
      margin: 0;
      background-color: #f4f4f4;
      padding: 20px;
      border-radius: 20px;
      max-width: fit-content;
      min-width: 500px;
      &.blue {
        background-color: #135bed;
        color: #fff;
      }
    }
    .message-time {
      color: #977a7a;
      font-weight: 600;
      font-size: 10px;
    }
  }

  .reply {
    display: flex;
    align-items: center;
    padding: 24px 0px;
    border-top: 1px solid #d9d9d9;
  }
  .ant-skeleton-element .ant-skeleton-image {
    border-radius: 100%;
  }
`;

const mapMessageDataForUser = (data, isCompany) => {
  const messages = data.messages || [];
  const userId = data.userId;
  const companyId = data.companyId || {};
  const adminId = data.adminId || {};

  const name = isCompany ? companyId.companyName : "Admin";
  const pictureURL = isCompany ? companyId.pictureURL : adminId.pictureURL;

  const mappedData = messages.map((item) => ({
    id: item._id,
    fullName: item.user ? userId.fullName : name,
    pictureURL: item.user ? userId.pictureURL : pictureURL,
    createdAt: item.createdAt,
    message: item.message,
    user: item.user,
    sender: !item.user,
  }));

  return mappedData;
};

const findRecipient = (data = {}) => {
  if (data.hasOwnProperty("companyId")) {
    return data.companyId;
  }
  if (data.hasOwnProperty("adminId")) {
    return data.adminId;
  }
  return {};
};

const mapMessagesForCompany = (data) => {
  const messages = data.messages || [];
  const userId = data.userId || {};
  const companyId = data.companyId || {};

  const mappedData = messages.map((item) => ({
    id: item._id,
    fullName: item.user ? userId.fullName : companyId.companyName,
    pictureURL: item.user ? userId.pictureURL : companyId.pictureURL,
    createdAt: item.createdAt,
    message: item.message,
    sender: item.user,
  }));

  return mappedData;
};
