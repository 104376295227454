import { useState } from "react";
import { Row, Col, Form, message } from "antd";
import styled from "styled-components";
import { InputWrapper, PhoneInputWrapper } from "components/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ButtonWrapper } from "components/buttons";
import { useMutation, Mutations, errorHandler } from "apis/config";
import { useHistory } from "react-router-dom";
import { INSTRUCTORS } from "redux/constants";
import { useDispatch } from "react-redux";
import ContentHeader from "../../components/header/contentHeader";
import moment from "moment";

const initialState = {
  instructorFullName: "",
  instructorEmail: "",
  instructorPassword: "",
  instructorPhoneNumber: "",
  phoneError: "",
};

const AddInstructor = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({ ...initialState });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const { mutateAsync: createUser, isLoading } = useMutation(Mutations.createInstructor);

  const handleCreate = async () => {
    if (isValidPhoneNumber(state.instructorPhoneNumber)) {
      try {
        const { data } = await createUser({
          instructorFullName: state.instructorFullName,
          instructorEmail: state.instructorEmail,
          instructorPassword: state.instructorPassword,
          instructorPhoneNumber: state.instructorPhoneNumber,
          lastLicenseUsed: moment().format("DD-MMM-YYYY HH:mm A"),
        });
        console.log("New Instructor: \n", data.data);
        dispatch({ type: INSTRUCTORS.INSERT, payload: data.data });
        history.push("/instructors");
      } catch (err) {
        console.log("Error", err);
        message.error(errorHandler(err));
      }
    } else {
      message.error("Invalid Phone number");
    }
  };

  return (
    <StyledContent>
      <ContentHeader title="Add Instructor" />
      <Form onFinish={handleCreate}>
        <div className="white-background">
          <Row gutter={[32, 0]}>
            <Col span={12}>
              <div className="label">Full Name</div>
              <Form.Item name="Full Name" rules={[{ required: true }]}>
                <InputWrapper
                  name="instructorFullName"
                  value={state.instructorFullName}
                  placeholder="Full Name"
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <div className="label">Email</div>
              <Form.Item name="Email" rules={[{ required: true }]}>
                <InputWrapper
                  name="instructorEmail"
                  value={state.instructorEmail}
                  placeholder="Email"
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <div className="label">Password</div>
              <Form.Item name="Password" rules={[{ required: true }]}>
                <InputWrapper.Password
                  name="instructorPassword"
                  value={state.instructorPassword}
                  placeholder="Password"
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <div className="label">Phone Number</div>
              <PhoneInputWrapper
                name="instructorPhoneNumber"
                autoComplete="new-password"
                value={state.instructorPhoneNumber}
                placeholder="Phone Number"
                onChange={handleChange}
                error={state.phoneError}
              />
            </Col>
            <Col offset={12} span={12}>
              <ButtonWrapper
                htmlType="submit"
                style={{ height: 40, width: "100%", marginTop: 16 }}
                loading={isLoading}
                onClick={() => {
                  if (!isValidPhoneNumber(state.instructorPhoneNumber)) {
                    setState({
                      ...state,
                      phoneError: "Invalid phone number",
                    });
                  }
                }}
              >
                Create
              </ButtonWrapper>
            </Col>
          </Row>
        </div>
      </Form>
    </StyledContent>
  );
};

export default AddInstructor;

const StyledContent = styled.div`
  .label {
    color: #786161;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .white-background {
    background-color: #fff;
    padding: 60px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%) !important;
    // min-height: 70vh;
    border-radius: 6px;
  }
`;
