import React, { useState, useEffect } from "react";
import { Row, Col, Tooltip, message, Modal, Form } from "antd";
import styled from "styled-components";
import SelectWrapper from "../input/selectWrapper";
import { InputWrapper } from "../input";
import { IoIosAddCircleOutline } from "react-icons/io";
import { SearchOutlined } from "@ant-design/icons";
import RangePickerWrapper from "components/input/rangePicker";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { LoadingOutlined } from "@ant-design/icons";
import { ButtonWrapper } from "components/buttons";
import { MdOutlineAddLink, MdOutlineLinkOff } from "react-icons/md";
import { BsInfoCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";

const StyledContent = styled.section`
  .heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .heading {
    font-size: 32px;
    color: #568eff;
    font-weight: 600;
    margin: 0 0 8px 0;
  }

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #d1d1d1;
    margin: 0 12px;
  }
`;

const ContentHeader = (props) => {
  const {
    title,
    count,
    onSearch,
    showRangeSelect,
    onSelectChange,
    onAdd,
    showSearch,
    meetingLink,
  } = props;
  const [form] = Form.useForm();

  const { userRole } = useSelector((state) => state.user.data);

  const [meetingLinkData, setMeetingLinkData] = useState("");
  const [meetingLinkState, setMeetingLinkState] = useState("");
  const [addLinkModal, toggleAddLinkModal] = useState(false);

  const { mutateAsync: getCompanyProfile, isLoading: getCompanyProfileLoading } =
    useMutation(Queries.getCompanyProfile);

  const { mutateAsync: updateMeetingLink, isLoading: meetingLinkLoading } = useMutation(
    Mutations.updateMeetingLink
  );

  useEffect(() => {
    if (userRole == 2) {
      getCompanyProfile()
        .then(({ data }) => {
          console.log("data------>", data.data.meetingLink);
          setMeetingLinkData(data.data.meetingLink);
        })
        .catch((err) => message.error(errorHandler(err)));
    }
  }, []);

  const closeModal = () => {
    setMeetingLinkState("");
    toggleAddLinkModal(false);
    form.resetFields();
  };

  const editMeetingLink = async () => {
    try {
      const { data } = await updateMeetingLink({
        meetingLink: meetingLinkState,
      });
      closeModal();
      window.location.reload();
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  return (
    <StyledContent>
      <Modal
        visible={addLinkModal}
        title="Add Training Link"
        footer={null}
        onCancel={closeModal}
      >
        <Form form={form} onFinish={editMeetingLink} validateTrigger="onFinish">
          <Form.Item
            name="Training Link"
            rules={[{ required: true, message: "Enter a valid URL", type: "url" }]}
          >
            <InputWrapper
              name="meetingLink"
              suffix={
                <Tooltip title="Make sure to add Https or Http before the link">
                  <BsInfoCircleFill style={{ color: "blue" }} />
                </Tooltip>
              }
              placeholder="https://zoom.us/"
              onChange={(e) => {
                setMeetingLinkState(e.target.value);
              }}
            />
          </Form.Item>
          <p style={{ marginTop: 20, marginBottom: 0 }}>
            <strong>Note:</strong> Make sure to add the training link just before the
            training starts, and also remove the training link after the training is over.
          </p>
          <ButtonWrapper
            htmlType="submit"
            loading={meetingLinkLoading}
            style={{ marginTop: 32 }}
          >
            Add
          </ButtonWrapper>
        </Form>
      </Modal>

      <Row justify="space-between">
        <Col>
          <div className="heading-wrapper">
            <h1 className="heading">{title}</h1>
            {count ? (
              <>
                <div className="dot" />
                <h1 className="heading">{count}</h1>
              </>
            ) : null}
          </div>
        </Col>

        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            {showSearch ? (
              <InputWrapper
                prefix={<SearchOutlined />}
                placeholder="Search"
                onChange={onSearch}
              />
            ) : null}

            <div style={{ width: 32 }} />
            {showRangeSelect ? (
              <RangePickerWrapper style={{ height: 40, borderColor: "#d1d1d1" }} />
            ) : null}
            <div style={{ width: 32 }} />

            {onSelectChange ? (
              <SelectWrapper
                size="large"
                defaultValue="View All"
                options={[
                  { label: "View All", value: "all" },
                  { label: "Active", value: "active" },
                  { label: "Inactive", value: "inactive" },
                ]}
                onChange={onSelectChange}
              />
            ) : null}

            {meetingLink ? (
              getCompanyProfileLoading ? (
                <LoadingOutlined
                  style={{
                    color: "#568eff",
                    fontSize: 40,
                    height: "auto",
                    marginLeft: 12,
                  }}
                />
              ) : meetingLinkData == "" ? (
                <Tooltip title="Add training link">
                  <MdOutlineAddLink
                    onClick={() => {
                      toggleAddLinkModal(true);
                    }}
                    style={{
                      cursor: "pointer",
                      color: "#568eff",
                      fontSize: 48,
                      height: "auto",
                      marginLeft: 12,
                    }}
                  />
                </Tooltip>
              ) : (
                <>
                  {meetingLinkLoading ? (
                    <>
                      <LoadingOutlined
                        style={{
                          color: "#568eff",
                          fontSize: 40,
                          height: "auto",
                          marginLeft: 12,
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Tooltip title="Remove training link">
                        <MdOutlineLinkOff
                          onClick={() => {
                            editMeetingLink();
                          }}
                          style={{
                            cursor: "pointer",
                            color: "#568eff",
                            fontSize: 48,
                            height: "auto",
                            marginLeft: 12,
                          }}
                        />
                      </Tooltip>
                    </>
                  )}
                </>
              )
            ) : null}

            {onAdd ? (
              <Tooltip title="Add new">
                <IoIosAddCircleOutline
                  onClick={onAdd}
                  style={{
                    cursor: "pointer",
                    color: "#568eff",
                    fontSize: 48,
                    height: "auto",
                    marginLeft: 12,
                  }}
                />
              </Tooltip>
            ) : null}
          </div>
        </Col>
      </Row>
    </StyledContent>
  );
};

ContentHeader.defaultProps = {
  title: "",
  count: null,
  onSearch: () => {},
  onFilter: () => {},
  showRangeSelect: false,
  onSelectChange: false,
  onAdd: false,
  showSearch: false,
};

export default ContentHeader;
