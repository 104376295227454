import { useRef, useState } from "react";
import { Modal, Row, Col } from "antd";
import { FaRegCalendarAlt } from "react-icons/fa";
import { AiOutlineFieldTime } from "react-icons/ai";
import styled from "styled-components";
import moment from "moment";
import { ButtonWrapper } from "components/buttons";
import CompleteEventModal from "./completeEventModal";

const ViewSchedulesModal = (props) => {
  const { visible, onCancel, events, title, isCompany } = props;
  const [completeModal, toggleCompleteModal] = useState(false);
  const eventRef = useRef();
  console.log("Events", events);
  return (
    <>
      <CompleteEventModal
        visible={completeModal}
        onCancel={() => toggleCompleteModal(false)}
        data={eventRef.current}
      />

      <StyledModal
        visible={visible}
        onCancel={onCancel}
        title={title}
        width={720}
        footer={null}
      >
        <div className="schedule-wrapper">
          {events?.map(({ data }) => (
            <div className="schedule">
              <Row key={data._id} justify="space-between" align="middle">
                <Col>
                  <div
                    className="instructor-pic"
                    style={{ backgroundImage: `url(${data.instructorId.pictureURL})` }}
                  />
                  <div style={{ marginLeft: 32 }}>
                    <div className="heading">{data.instructorId.fullName}</div>
                    <div className="desc">{data.instructorId.email}</div>
                    <div className="desc">{data.instructorId.phoneNumber}</div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Participants:{" "}
                      <div style={{ margin: "0px 6px" }}>
                        {data.userIds.length <= 6 ? (
                          data.userIds.map((user) => (
                            <div
                              key={user._id}
                              className="participants-circle"
                              style={{ backgroundImage: `url(${user.pictureURL})` }}
                            />
                          ))
                        ) : (
                          <>
                            {data.userIds.map((user) => (
                              <div
                                key={user._id}
                                className="participants-circle"
                                style={{ backgroundImage: `url(${user.pictureURL})` }}
                              />
                            ))}
                            {` +${data.userIds.length - 6} more`}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <section style={{ display: "flex", alignItems: "center" }}>
                    <FaRegCalendarAlt className="icons" />
                    <div>
                      {moment(data.trainingDate).format("dddd")} <br />{" "}
                      {moment(data.trainingDate).format("DD, MMM YYYY")}
                    </div>
                  </section>

                  <section
                    style={{ display: "flex", alignItems: "center", margin: "12px 0" }}
                  >
                    <AiOutlineFieldTime className="icons" />
                    <div>{data.trainingTime}</div>
                  </section>

                  {isCompany ? (
                    <ButtonWrapper
                      style={{ width: "100%" }}
                      disabled={data.status == 1 ? false : true}
                      onClick={() => {
                        eventRef.current = data;
                        toggleCompleteModal(true);
                      }}
                    >
                      {data.status == 1 ? "Mark attendance" : "Completed"}
                    </ButtonWrapper>
                  ) : null}
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </StyledModal>
    </>
  );
};

export default ViewSchedulesModal;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 24px 48px;
  }
  .schedule {
    border: 1px solid #d9d9d9;
    border-radius: 12px 12px 12px 0;
    height: 160px;
    padding: 12px 24px;
    margin: 12px 0;
    background-color: #4f56c0;
    color: #fff;
  }

  .icons {
    color: #fff;
    font-size: 32px;
    margin-right: 12px;
  }

  .heading {
    font-size: 24px;
    font-weight: 600;
  }
  .desc {
    font-size: 14px;
    font-weight: 600;
    margin: 6px 0;
  }
  .instructor-pic {
    width: 64px;
    height: 64px;
    left: -54px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    border: 2px solid #568eff;
  }
  .participants-circle {
    height: 25px;
    width: 25px;
    border-radius: 50px;
    background-position: center;
    background-size: cover;
    margin-left: -5px;
    display: inline-flex;
  }
`;
