import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import HeaderWrapper from "components/header/headerWrapper";
import FooterWrapper from "components/footer/footerWrapper";
import SidebarWrapper from "components/sidebar/sidebarWrapper";
import maintainance from "../../images/maintainance.gif";
import { useMutation, Mutations, errorHandler } from "apis/config";
import { logoutAction } from "redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { Result, Button, message } from "antd";
import { Link, useHistory } from "react-router-dom";

const { Content } = Layout;

const GuideShell = () => {
  const [collapsed, toggleCollapsed] = useState(false);

  return (
    <div>
      <Layout>
        <div style={{ maxHeight: "100vh" }}>
          <SidebarWrapper collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
        </div>

        <Layout>
          <HeaderWrapper collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
          <div
            style={{
              maxHeight: "calc(100vh - 49px)",
              overflow: "auto",
              backgroundColor: "#F4F6FB",
            }}
          >
            <section style={{ minHeight: "calc(100vh - 115px)", overflow: "auto" }}>
              <Content style={{ padding: 44, backgroundColor: "#F4F6FB" }}>
                <Switch>
                  <Route exact path="/" component={Dashboard} />
                </Switch>
              </Content>
            </section>
            <FooterWrapper />
          </div>
        </Layout>
      </Layout>
    </div>
  );
};

export default GuideShell;

const Dashboard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { deviceId, userRole } = useSelector((state) => state.user.data);

  const { isLoading, mutateAsync } = useMutation(Mutations.logout);

  const handleLogout = async () => {
    try {
      await mutateAsync({ deviceId });
    } catch (err) {
      message.error(errorHandler(err));
    } finally {
      dispatch(logoutAction());
      history.push("/");
    }
  };

  return (
    <div>
      <Result
        status="500"
        title="Feature not available"
        subTitle="Sorry, the guide login feature is not available at the moment"
        extra={
          <Button
            loading={isLoading}
            onClick={handleLogout}
            style={{ width: 200, borderRadius: 6, height: 48 }}
            type="primary"
          >
            Logout
          </Button>
        }
      />
    </div>
  );
};
