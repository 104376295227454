import React, { useEffect, useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Col, Form, Row, message } from "antd";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper, PhoneInputWrapper } from "components/input";
import { Mutation, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { errorHandler } from "helpers/errorHandler";
import PoweredByStripe from "../../images/powered-by-stripe.svg";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Mutations } from "apis/mutations";
import { LoadingOutlined } from "@ant-design/icons";
import { BsArrowRight } from "react-icons/bs";
import { BiRightArrowAlt } from "react-icons/bi";

const CARD_ELEMENT_OPTIONS = {
  showIcon: true,
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      // iconColor: "rgb(240, 57, 122)",
      // color: "rgb(240, 57, 122)",
      fontSize: "16px",
      fontFamily: '"Open Sans", sans-serif',
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  },
};

const initialState = {
  phoneNo: "",
  fullName: "",
  phoneError: "",
  email: "",
};

const CheckoutForm = (props) => {
  const [state, setState] = useState({ ...initialState });
  const [isLoading, setIsLoading] = useState(false);
  const { paymentVeriyData, setPaymentVerifyData } = props;
  const params = useParams();
  const stripe = useStripe();
  const [form] = Form.useForm();
  const elements = useElements();

  const { mutateAsync } = useMutation(Mutations.quotePayment);

  const handleSubmit = async (event) => {
    try {
      if (!stripe || !elements) {
        message.error("Stripe is not yet loaded.");
        return;
      }

      if (isValidPhoneNumber(state.phoneNo)) {
        setState({ ...state, phoneError: "" });
        setIsLoading(true);
        const paymentMethod = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
            email: state.email,
            phone: state.phoneNo,
            name: state.fullName,
          },
        });

        if (paymentMethod?.paymentMethod.id) {
          const { data } = await mutateAsync({
            paymentMethodId: paymentMethod.paymentMethod.id,
            email: state.email,
            quoteId: params.quoteId,
          });
          if (data) {
            setPaymentVerifyData(data.data);
            setIsLoading(false);
          }
        }
      } else {
        setState({ ...state, phoneError: "Please enter valid phone number" });
      }
    } catch (err) {
      setIsLoading(false);
      setPaymentVerifyData("");
      message.error(errorHandler(err));
    }
    // Use the card number value to create a payment method
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <Form form={form} onFinish={handleSubmit} validateTrigger="onFinish">
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <h4 className="form-label">Email*</h4>
          <Form.Item
            name="Email"
            rules={[{ type: "email", required: true, message: "Invalid Email" }]}
          >
            <InputWrapper
              name="email"
              value={state.email}
              placeholder="abc@xyz.com"
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <h4 className="form-label">Full Name*</h4>
          <Form.Item
            name="Full Name"
            rules={[{ type: "string", required: true, message: "Full Name is required" }]}
          >
            <InputWrapper
              name="fullName"
              value={state.fullName}
              placeholder="Full Name"
              onChange={handleChange}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <h4 className="form-label">Phone*</h4>
          <Form.Item
            name="PhoneNumber"
            rules={[{ type: "string", required: true, message: "Invalid Phone Number" }]}
          >
            <PhoneInputWrapper
              style={{ height: 50 }}
              name="phoneNo"
              value={state.phoneNo}
              placeholder="Phone Number"
              onChange={handleChange}
              error={state.phoneError}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <h4 className="form-label">Card Details*</h4>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </Col>

        <Col span={24}>
          <ButtonWrapper
            style={{ height: 50, marginTop: 30, width: "100%", borderRadius: 6 }}
            disabled={isLoading}
            htmlType="submit"
          >
            <span style={{ color: "#000", fontWeight: 700, fontSize: 22 }}>
              PAY ${paymentVeriyData?.amount}
            </span>
            {isLoading ? (
              <LoadingOutlined style={{ fontSize: 22, fontWeight: 700, marginLeft: 4 }} />
            ) : (
              <BiRightArrowAlt style={{ fontSize: 22, fontWeight: 700, marginLeft: 4 }} />
            )}
          </ButtonWrapper>
        </Col>
        {/* </div> */}
      </Row>
    </Form>
  );
};

export default CheckoutForm;
