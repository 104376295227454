import { useState } from "react";
import { Modal, Form, message } from "antd";
import { InputWrapper, TextAreaWrapper } from "components/input";
import { ButtonWrapper } from "components/buttons";
import { useMutation, errorHandler, Mutations } from "apis/config";

const initialState = {
  title: "",
  description: "",
  extension: "",
  base64: "",
};

const AddDocumentModal = (props) => {
  const { visible, onCancel, onComplete } = props;
  const [form] = Form.useForm();
  const [state, setState] = useState({ ...initialState });
  const onClose = () => {
    onCancel();
    setState({ ...initialState });
  };

  const handleUpload = (e) => {
    const { files } = e.target;
    const binaryReader = new FileReader();

    if (files[0].size == 0) {
      // message.error("Can not upload empty file");
      const [_, extension] = files[0].name.split(".");
      setState({ ...state, base64: "", extension: extension });
    } else {
      binaryReader.readAsDataURL(files[0]);
      binaryReader.onloadend = (e) => {
        const [G, base64] = e.target.result.split("base64,");
        const [_, extension] = files[0].name.split(".");
        console.log("Extension", extension);
        setState({ ...state, base64, extension });
      };
    }
  };

  const { mutateAsync: addDocument, isLoading: addDocumentLoading } = useMutation(
    Mutations.addDocument
  );

  const handleAddDocument = async () => {
    try {
      const { data } = await addDocument(state);
      onComplete(data.data);
      closeModal();
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const closeModal = () => {
    onCancel();
    setState({ ...initialState });
    form.resetFields();
  };

  return (
    <Modal visible={visible} title="Add document" onCancel={closeModal} footer={null}>
      <Form form={form} onFinish={handleAddDocument}>
        <div className="form-label">Title</div>
        <Form.Item name="Title" rules={[{ required: true }]}>
          <InputWrapper
            placeholder="Title"
            value={state.title}
            onChange={(e) => setState({ ...state, title: e.target.value })}
          />
        </Form.Item>

        <div className="form-label">Description</div>
        <Form.Item name="Description" rules={[{ required: true }]}>
          <TextAreaWrapper
            rows={4}
            placeholder="Description"
            value={state.description}
            onChange={(e) => setState({ ...state, description: e.target.value })}
          />
        </Form.Item>

        <div className="form-label">Upload</div>
        <Form.Item name="File" rules={[{ required: true }]}>
          <input
            id="file-upload"
            onChange={handleUpload}
            // name="input"
            type="file"
            // accept=".jpeg, .jpg, .png"
          />
        </Form.Item>

        <ButtonWrapper htmlType="submit" loading={addDocumentLoading}>
          Add
        </ButtonWrapper>
      </Form>
    </Modal>
  );
};

export default AddDocumentModal;
