import React, { useState, useEffect } from "react";
import ContentHeader from "components/header/contentHeader";
import { Queries, useMutation, errorHandler } from "apis/config";
import { message, Row, Col } from "antd";
import AddDocumentModal from "components/modals/addDocumentModal";
import DocumentCard from "components/cards/documentCard";
import styledComponents from "styled-components";
import VideoCardSkeleton from "components/cards/videoCardSkeleton";
import NotFoundImage from "../../images/not-found.gif";

const StyledDiv = styledComponents.div`
.white-background{
  background-color: #fff;
  padding: 60px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 15%) !important;
  // min-height: 70vh;
  border-radius: 6px;
}
`;

const Document = (props) => {
  const [allDocuments, setAllDocuments] = useState([]);
  const [documentModal, toggleDocumentModal] = useState(false);
  const { mutateAsync: getAllDocumentsOfCompany, isLoading: documentsLoading } =
    useMutation(Queries.getAllDocumentsOfCompany);

  useEffect(() => {
    getAllDocumentsOfCompany()
      .then(({ data }) => {
        setAllDocuments(data.data);
      })
      .catch((e) => message.error(errorHandler(e)));
  }, []);

  return (
    <StyledDiv>
      <AddDocumentModal
        visible={documentModal}
        onCancel={() => toggleDocumentModal(false)}
        onComplete={setAllDocuments}
      />
      <div>
        <ContentHeader title="Documents" onAdd={() => toggleDocumentModal(true)} />
        <div className="white-background">
          <Row gutter={[24, 24]}>
            {documentsLoading ? (
              [0, 1, 2, 3, 4, 5].map((item) => (
                <Col key={item} sm={12} md={12} lg={8}>
                  <VideoCardSkeleton />
                </Col>
              ))
            ) : (
              <>
                {allDocuments.length == 0 ? (
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <img src={NotFoundImage} style={{ height: 300, width: 300 }} />
                      <p
                        style={{
                          fontSize: 18,
                          color: "rgb(86, 142, 255)",
                          textAlign: "center",
                        }}
                      >
                        No documents uploaded
                      </p>
                    </div>
                  </Col>
                ) : (
                  allDocuments.map((item) => (
                    <Col>
                      <DocumentCard {...item} onDelete={setAllDocuments} />
                    </Col>
                  ))
                )}
              </>
            )}
          </Row>
        </div>
      </div>
    </StyledDiv>
  );
};

export default Document;
