export const USER = {
  LOGIN: "LOGIN_AS_ADMIN",
  LOGOUT: "LOGOUT_ADMIN",
  UPDATE: "UPDATE_ADMIN_INFO",
};

export const INSTRUCTORS = {
  STORE: "STORE_ALL_INSTRUCTORS",
  INSERT: "INSERT_INSTRUCTOR",
};

export const ALL_USERS = {
  STORE: "STORE_ALL_USERS",
  INSERT: "INSERT_USER",
};

export const ALL_PACKAGES = {
  STORE: "STORE_PACKAGES_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_PACKAGES_STATUS",
  ADD_NEW: "ADD_NEW_PACKAGES",
  EDIT_DATA: "EDIT_PACKAGES_DATA",
};

export const ALL_ASSOSIATION = {
  STORE: "STORE_ASSOSIATION_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_ASSOSIATION_STATUS",
  ADD_NEW: "ADD_NEW_ASSOSIATION",
  EDIT_DATA: "EDIT_ASSOSIATION_DATA",
  DELETE_DATA: "DELETE_ASSOSIATION_DATA",
};
